import * as React from "react";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import AddClienti from "../Add/AddClienti";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getDataFromStorage } from "storage/StorageData";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import { Clear, DonutLarge, Search } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { i18n, t } = useTranslation();

  const headCells = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: t("id"),
    },
    {
      id: "nome",
      numeric: false,
      disablePadding: true,
      label: t("nomeECognome"),
    },

    {
      id: "città",
      numeric: true,
      disablePadding: false,
      label: t("città"),
    },
    {
      id: "provincia",
      numeric: true,
      disablePadding: false,
      label: t("provincia"),
    },
    {
      id: "partita",
      numeric: true,
      disablePadding: false,
      label: t("partita") + " " + t("iva"),
    },
    {
      id: "codiceFiscale",
      numeric: true,
      disablePadding: false,
      label: t("codiceFiscale"),
    },

    {
      id: "note",
      numeric: true,
      disablePadding: false,
      label: t("note"),
    },
    // {
    //   id: "saldo",
    //   numeric: true,
    //   disablePadding: false,
    //   label: t("saldo"),
    // },
    {
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleRemoveSearch,
    setRows,
    originalData,
    listOfClient,
    setListOfClient,
    client,
    setClient,
    inputClient,
    setInputClient,
    searchID,
    setSearchID,
    searchName,
    setSearchName,
    searchFiscalCode,
    setSearchFiscalcode,
    searchVatNumber,
    setSearchVatNumber,
    isSearch,
    setIsSearch,
    fnGetAllCustomers,
    page,
    searchAllCustomers,
    limit,
    setPage,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", display: "flex", alignItems: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            size="small"
            id="outlined-select-currency"
            // select
            label={t("ID")}
            sx={{
              width: "5pc",
              marginLeft: "8px",
            }}
            value={searchID}
            onChange={(e) => setSearchID(e.target.value)}
          />

          <Autocomplete
            size="small"
            value={client}
            onChange={(event, newClient) => {
              setClient(newClient);
            }}
            inputValue={inputClient}
            onInputChange={(event, inputClient) => {
              setInputClient(inputClient);
            }}
            id="controllable-states-demo"
            options={listOfClient}
            getOptionLabel={(option) => {
              if (!option) return "";
              // Per "Persona Fisica" mostra nome + cognome, altrimenti solo nome
              return option.subjectTypeId?.type === "Persona Fisica"
                ? `${option.name || ""} ${option.lastName || ""}`
                : option.name || "";
            }}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return false;
              return option._id === value._id;
            }}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.subjectTypeId?.type === "Persona Fisica"
                  ? `${option.name || ""} ${option.lastName || ""}${
                      option.vatNumber ? ` - ${option.vatNumber}` : ""
                    }`
                  : `${option.name || ""}${
                      option.vatNumber ? ` - ${option.vatNumber}` : ""
                    }`}
              </li>
            )}
            sx={{ width: "25pc", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField size="small" {...params} label={t("clienti")} />
            )}
          />

          <TextField
            size="small"
            id="outlined-basic"
            sx={{ width: "10pc", marginLeft: "8px" }}
            label={"*" + t("partita") + " " + t("iva")}
            placeholder="100"
            variant="outlined"
            value={searchVatNumber}
            onChange={(e) => setSearchVatNumber(e.target.value)}
          />
          <TextField
            size="small"
            id="outlined-select-currency"
            // select
            label={t("codiceFiscale")}
            sx={{
              width: "10pc",
              marginLeft: "8px",
            }}
            value={searchFiscalCode}
            onChange={(e) => setSearchFiscalcode(e.target.value)}
          />

          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={async (e) => {
              setPage(0);
              if (
                searchID != "" ||
                client != null ||
                searchVatNumber != "" ||
                searchFiscalCode != ""
              ) {
                await searchAllCustomers(1, limit);
                setIsSearch(true);
              } else {
                fnGetAllCustomers(1, limit);
              }
            }}
          >
            <Search />
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={() => handleRemoveSearch()}
          >
            <Clear />
          </Button>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              props.onDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          sx={{
            "&:hover": {
              backgroundColor: "#ffffff",
              boxShadow: "none",
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{
                marginLeft: "16px",
              }}
              onClick={() => {
                props.onAddOpen();
                // handleRemoveSearch();
                // setRows(originalData);
              }}
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
            >
              {t("nuovo")}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func,
  searchContent: PropTypes.string,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("cognome");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [searchContent, setSearchContent] = useState("");
  const [clientObj, setClientObj] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [filterOriginal, setFilterOriginal] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const [listOfClient, setListOfClient] = useState([]);
  const [client, setClient] = useState(null);
  const [inputClient, setInputClient] = useState("");
  const [searchID, setSearchID] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchVatNumber, setSearchVatNumber] = useState("");
  const [searchFiscalCode, setSearchFiscalcode] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if (isSearch) {
      await searchAllCustomers(newPage + 1, limit);
    } else {
      await fnGetAllCustomers(newPage + 1, limit);
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    let newPage = 0;
    setPage(newPage);
    if (isSearch) {
      await searchAllCustomers(newPage + 1, newLimit);
    } else {
      await fnGetAllCustomers(newPage + 1, newLimit);
    }
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (nome) => selected.indexOf(nome) !== -1;

  const getCustomerList = () => {
    API.get(apiURL.service_get_all_customers)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfClient(res?.data?.data?.customers);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout();
          }
        }
      });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );
  const { i18n, t } = useTranslation();

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const fnGetAllCustomers = useCallback(
    async (page, limit) => {
      setIsLoading(true);
      let accountingSubject = await getDataFromStorage("accountingSubject");
      // id=${accountingSubject?._id}&
      API.get(
        `${apiURL.service_get_customer}?id=${accountingSubject?._id}&page=${page}&limit=${limit}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            // console.log("Response = ", JSON.stringify(res.data.data));
            setRows(res.data.data.customers);
            // setOriginalData(res.data.data.customers);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            // console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const searchAllCustomers = async (pageValue, newLimit) => {
    setIsLoading(true);

    let accountingSubject = await getDataFromStorage("accountingSubject");
    const clientId = client && client._id;
    const queryParams = {
      id: accountingSubject?._id,
      page: pageValue,
      limit: newLimit,
    };

    if (searchID) {
      queryParams.indexNo = searchID;
    }

    if (searchVatNumber) {
      queryParams.vatNumber = searchVatNumber;
    }

    if (clientId) {
      queryParams.clientId = clientId;
    }

    if (searchFiscalCode) {
      queryParams.fiscalCode = searchFiscalCode;
    }

    // Convert the queryParams object to a query string
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    await API.get(`${apiURL.service_filter_customer}?${queryString}`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setRows(res?.data?.data?.customers);
          // setFilterOriginal(res?.data?.data?.customers);
          setTotalRecords(res.data.data.totalRecords);
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(true);
            setErrorMsg(t("noRecordFound"));
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout();
          }
        }
        setRows([]);
        setOriginalData([]);
        setTotalRecords(0);
      });
  };

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      customerIds: selected,
    });

    API.post(apiURL.service_delete_customer, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          if (isSearch) {
            await searchAllCustomers(page + 1, limit);
          } else {
            await fnGetAllCustomers(page + 1, limit);
            handleRemoveSearch();
          }

          // const filteredArr = rows.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setRows(filteredArr);
          // const filteredOriginalArr = filterOriginal.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setOriginalData(filteredOriginalArr);
          setSelected([]);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout();
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    console.log("selected:: ", selected);
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  useEffect(() => {
    getCustomerList();
    fnGetAllCustomers(page + 1, limit);
  }, []);

  const navigateToUpdate = (data) => {
    console.log("Clicked Data = ", JSON.stringify(data));
    setClientObj(data);
    setEdit(true);
    setOpenAddUser(!openAddUser);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function handleRemoveSearch() {
    setSearchID("");
    setSearchVatNumber("");
    setSearchFiscalcode("");
    setClient(null);
    setInputClient("");
    setIsSearch(false);
    setTotalRecords(0);
    await fnGetAllCustomers(page + 1, limit);
  }

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorIndex(null);
  };
  const open1 = Boolean(anchorEl);

  return (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      {isLoading && (
        <Box>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      )}
      <Box>
        <Typography variant="h3" mb={2}>
          {t("anagraficaClienti")}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddOpen={() => {
            setOpenAddUser(!openAddUser);
          }}
          // handleSearch={(event) => {
          //   const search = event.target.value;
          //   setSearchContent(search);
          //   if (search.trim().length > 0) {
          //     const filteredData = filterOriginal.filter((item) =>
          //       item.name.toLowerCase().includes(search.toLowerCase())
          //     );
          //     setRows(filteredData);
          //   } else {
          //     setRows(originalData);
          //   }
          // }}
          onDelete={() => {
            setOpenDelete(!openDelete);
          }}
          setSearchContent={setSearchContent}
          handleRemoveSearch={handleRemoveSearch}
          setRows={setRows}
          originalData={originalData}
          searchContent={searchContent}
          searchAllCustomers={searchAllCustomers}
          listOfClient={listOfClient}
          setListOfClient={setListOfClient}
          client={client}
          setClient={setClient}
          inputClient={inputClient}
          setInputClient={setInputClient}
          searchID={searchID}
          setSearchID={setSearchID}
          searchVatNumber={searchVatNumber}
          setSearchVatNumber={setSearchVatNumber}
          searchName={searchName}
          setSearchName={setSearchName}
          searchFiscalCode={searchFiscalCode}
          setSearchFiscalcode={setSearchFiscalcode}
          setIsSearch={setIsSearch}
          isSearch={isSearch}
          page={page}
          fnGetAllCustomers={fnGetAllCustomers}
          limit={limit}
          setPage={setPage}
        />

        <TableContainer
          sx={{
            maxHeight: "70vh",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row._id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {row?.auto_customerId ? row?.auto_customerId : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row?.subjectTypeId?.type == "Persona Fisica"
                        ? row?.name + " " + row?.lastName
                        : row?.name}
                    </TableCell>
                    <TableCell align="left">
                      {row?.city ? row?.city : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row?.province ? row?.province : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row?.vatNumber ? row?.vatNumber : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row?.taxIdCode ? row?.taxIdCode : ""}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row?.note?row?.note:""} */}
                      {row?.note ? (
                        <>
                          <InfoIcon
                            aria-owns={
                              open1 ? `mouse-over-popover-${index}` : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={(e) => handlePopoverOpen(e, index)}
                            onMouseLeave={handlePopoverClose}
                            sx={{ color: "#4D96FF" }}
                          />
                          <Popover
                            id={`mouse-over-popover-${index}`}
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={open1 && index === anchorIndex}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography
                              sx={{ p: 1, boxShadow: "0", fontSize: "14px" }}
                            >
                              {row?.note}
                            </Typography>
                          </Popover>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    {/* <TableCell align="left">
                      {row?.saldo == 0
                        ? ""
                        : row?.saldo.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell> */}
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          navigateToUpdate(row);
                          // handleRemoveSearch();
                          // setRows(originalData);
                        }}
                      >
                        {t("edit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!isSearch &&  ( */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("of")} ${count}`
          }
        />
        {/* )} */}
      </Paper>
      <AddClienti
        open={openAddUser}
        isEdit={isEdit}
        stateObj={clientObj}
        handleClose={async (data) => {
          if (data?.reload) {
            if (isSearch) {
              await searchAllCustomers(page + 1, limit);
            } else {
              await fnGetAllCustomers(page + 1, limit);
              await getCustomerList();
            }
          }
          setEdit(false);
          setClientObj(null);
          setOpenAddUser(!openAddUser);
        }}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(!openDelete)}
        onConfirm={confirmDelete}
      />
      <>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            sx={{ color: "#fff" }}
            severity={severity}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </>
    </Box>
  );

  // return (
  //   <>
  //     {isLoading ? LoadingSpinner : CustomerList}
  //     <Snackbar
  //       open={open}
  //       autoHideDuration={6000}
  //       onClose={handleAlertClose}
  //       sx={{ width: "100%" }}
  //       anchorOrigin={{ horizontal: "right", vertical: "top" }}
  //     >
  //       <Alert
  //         onClose={handleAlertClose}
  //         sx={{ color: "#fff" }}
  //         severity={severity}
  //       >
  //         {errorMsg}
  //       </Alert>
  //     </Snackbar>
  //   </>
  // );
}
