/***
 @description
 */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
} from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import StepContent from "@mui/material/StepContent";

import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { getDataFromStorage } from "storage/StorageData";
import AppLogger from "constants/AppLogger";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL/commonFunctions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddEsercizi = (props) => {
  const { i18n, t } = useTranslation();
  const steps = [
    {
      label: t("seiSicuro") + "?",
    },
    {
      label: t("confermaOperazione"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const fnUpdateExerise = async () => {
    let accountingSubject = await getDataFromStorage("accountingSubject");

    setIsLoading(true);

    let updateObj = {};
    updateObj.state = "open";
    updateObj.year = props?.stateObj?.year;
    updateObj.accountingSubjectId = accountingSubject?._id;

    API.put(
      `${apiURL.service_update_exercise}/${props?.stateObj?._id}`,
      updateObj
    )
      .then(async (response) => {
        setIsLoading(false);
        console.log(response.data);
        if (response.status === apiStatusCode.SUCCESS) {
          setOpen(true);
          setErrorMsg(t("common.updateSuccess"));
          setSeverity("success");
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        console.log(error)
        AppLogger.logError(JSON.stringify(error));
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            if (error?.response?.data?.data?.subject == 1) {
              setErrorMsg(t("excercise.mustConsecutiveOpen"));
            }
            else {
              setErrorMsg(t("excercise.notAllowedToOpen"));
            }
          }
        }
      });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (activeStep == 1) {
      await fnUpdateExerise();
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = async () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    await fnUpdateExerise();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const backToList = () => {
    setActiveStep(0);
    setCompleted({});
    props.handleClose({ reload: false });
  };

  const statusModal = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            borderRadius: 2,
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {t("apriEsercizioChiuso")}
          </Typography>

          <Button variant="contained" size="small" onClick={backToList}>
            {t("indietro")}
          </Button>
        </Box>
        <Divider />

        <Box
          sx={{
            height: "50vh",
            overflowY: "auto",
            py: 3,
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
              border: "4px solid #ffffff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#2c70cf",
            },
          }}
        >
          <Box
            component="form"
            sx={{
              maxWidth: 550,
              width: "100%",
              margin: "auto",
            }}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={step.label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {step.label}
                  </StepButton>

                  <StepContent></StepContent>
                </Step>
              ))}
            </Stepper>

            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  {/* <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box> */}
                  <TextField
                    size="small"
                    id="standard-multiline-static"
                    sx={{ width: "100%" }}
                    label={t("confermaOperazione") + "?"}
                    multiline
                    rows={2}
                    defaultValue="Sei veramente sicuro di confermare?
                        Perchè è possibile che tu stia facendo una cazzata!"
                    variant="standard"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                    {/* {t('passo')} {activeStep + 1} */}
                  </Typography>

                  {activeStep === 0 ? (
                    <>
                      <Typography>
                        Sei sicuro di voler riaprire l'esercizio{" "}
                        <strong>{props?.stateObj?.year}</strong>? l’esercizio è
                        già chiuso, riaprirlo comporta la modifica dei saldi di
                        bilancio.
                      </Typography>
                      <Divider />
                    </>
                  ) : // <TextField
                  //       id="standard-multiline-static"
                  //       sx={{width:"100%"}}
                  //       label={t('seiSicuro')+"?"}
                  //       multiline
                  //       rows={2}
                  //       defaultValue="Sei sicuro di voler riaprire l'esercizio?
                  //       l’esercizio è già chiuso, riaprirlo comporta la modifica dei saldi di bilancio."
                  //       variant="standard"
                  //     >

                  // </TextField>

                  activeStep === 1 ? (
                    <TextField
                      size="small"
                      id="standard-multiline-static"
                      sx={{ width: "100%" }}
                      label={t("confermaOperazione") + "?"}
                      multiline
                      rows={2}
                      defaultValue="Sei veramente sicuro di confermare?
                        Perchè è possibile che tu stia facendo una cazzata!"
                      variant="standard"
                    />
                  ) : (
                    <Typography>No</Typography>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      size="small"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      {t("indietro")}
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    {/* <Button variant='contained' onClick={handleNext}  sx={{ mr: 1 }}>
                  {t('continua')}
                  </Button> */}
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      {t("continua")}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : statusModal}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddEsercizi;
