/***
 @description
 */

class Logger {
  static logError(error) {
    let isLocalHost = window.location.href.includes("localhost");
    if(isLocalHost) {
      console.error(error)
    }
  }

  static logInfo(info) {
    let isLocalHost = window.location.href.includes("localhost");
    console.log(isLocalHost)
    if(isLocalHost) {
      console.log(info)
    }
  }
}

export default Logger
