/***
 @description
 */
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import AppLogger from "constants/AppLogger";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { DeleteDialog } from "components/DeleteDialog";
import { resetToken } from "apiURL/commonFunctions";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddUser = (props) => {
  const { i18n, t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const[severity,setSeverity]=useState("success")
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("nome") + " " + t("richiede")),
    lastName: Yup.string().optional(),
    email: Yup.string()
      .email(t("nonValidoEmail"))
      .required(t("email") + " " + t("richiede")),
    password: Yup.string()
      .min(8, t('passwordLength'))
      .required(t("password") + " " + t("richiede")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required(t("cpassword") + " " + t("richiede")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("Form values:", values);
      await fnAddUser();
    },
  });

  useEffect(() => {
    console.log("Add User = ", JSON.stringify(props?.stateObj));
    if (props) {
      if (props?.stateObj) {
        // setIsEdit(true)
        formik.setValues({
          name: props?.stateObj?.firstName,
          lastName: props?.stateObj?.lastName,
          email: props?.stateObj?.email,
          password: props?.stateObj?.password,
          confirmPassword: props?.stateObj?.password,
        });
      }
    }
  }, [props, props?.stateObj]);

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const fnAddUser = async () => {
    const userId = JSON.parse(localStorage.getItem("user"))?._id;
    if (!!formik.values) {
      setIsLoading(true);

      const objUser = {};

      objUser.email = formik.values.email;
      objUser.password = formik.values.password;
      objUser.firstName = formik.values.name;
      objUser.lastName = formik.values.lastName;
      objUser.createdBy = userId;

      API.post(apiURL.service_create_user, objUser)
        .then((response) => {
          setIsLoading(false);
          setReload(true)
          console.log(response.data);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setErrorMsg(t("common.addSuccess"));
            setSeverity("success")
            // dispatch({type: "LOGIN", payload: response.data.data.user})
            // localStorage.setItem("userToken", JSON.stringify(response.data.data.token))
            formik.resetForm();

            if (isSaveNConti) {
              setIsSaveNConti(false);
            } else {
              // setIsEdit(false)
              props.handleClose({ reload: true });
            }
          } else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch(async(error) => {
          AppLogger.logError(JSON.stringify(error));
          setIsLoading(false)
          setOpen(true);
          setSeverity("error")
            if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
               await doLogout()
              }else if(error.response.data.errors[0].msg=="User is already exists!"){
                setErrorMsg(t("userExist"))
              }
              else{
                setErrorMsg(t("common.badRequest"));
              }
            } 
        });
    } 
  };

  const fnEditUser = async () => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    console.log(formik.values);
    if (!!formik.values) {
      setIsLoading(true);

      const objUser = {};

      objUser.email = formik.values.email;
      objUser.firstName = formik.values.name;
      objUser.lastName = formik.values.lastName;

      API.put(`${apiURL.service_update_user}/${props.stateObj?._id}`, objUser)
        .then((response) => {
          setIsLoading(false);
          setReload(true)
          console.log(response.data);
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setErrorMsg(t("common.updateSuccess"));
            setSeverity("success")
            formik.resetForm();
            props.handleClose({ reload: true });
          } else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          AppLogger.logError(JSON.stringify(error));
          console.log(error);
          setIsLoading(false)
          setOpen(true);
          setSeverity("error")
          if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              }
              else{
                setErrorMsg(t("common.badRequest"));
              }
            } 
        });
      // axios.put(`${apiURL.service_update_user}/${props.stateObj?._id}`, objUser, {
      //     'headers': {
      //         'Accept': 'application/json',
      //         'Authorization': userToken
      //     }
      // })
    } 
  };

  const backToList = (reload) => {
    props.handleClose({reload: reload});
    formik.resetForm();
    setIsSaveNConti(false);
  }

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  };

  const confirmDelete = async () => {
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  const fnDelete = async () => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const idsToBeDeleted = JSON.stringify({
      userIds: [props.stateObj?._id],
    });

    API.post(apiURL.service_delete_users, idsToBeDeleted)
      .then((response) => {
        // await axios.post(apiURL.service_delete_users, idsToBeDeleted, {
        //   'headers': {
        //     'Content-Type': 'application/json',
        //     'Authorization': token
        //   }
        // })
        // .then(async (res) => {
        console.log(response.data);
        if (response.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success")
          formik.resetForm();
          // setIsEdit(false)
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setSeverity("error")
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false)
        setOpen(true);
        setSeverity("error")
        if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            }
            else{
              setErrorMsg(t("common.badRequest"));
            }
          } 
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };

  const UserModal = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      // onClose={props.handleClose}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          // padding: 2,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 1.5,
            // position: "fixed"
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {props?.isEdit
              ? t("changeUser")
              : t("create")+ " "+ t("user")}
          </Typography>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              backToList(reload);
            }}
          >
            {t("indietro")}
          </Button>
        </Box>
        <Divider />

        <Box
          sx={{
            width: "100%",
            height: "70vh",
            overflowY: "auto",
            py:3,
            '&::-webkit-scrollbar': {
              width: '16px',
              borderRadius:"10px",

            },
            '&::-webkit-scrollbar-track': {
              background: "#fff",
              borderRadius:"8px",

            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#4d96ff',
              borderRadius:"10px",
              border: "4px solid #ffffff",
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#2c70cf'
            }
            // display:"flex",
            // alignItems:"center",
            // justifyContent:"center",
          }}
        >
          <Box
            component="form"
            // onSubmit={formik.handleSubmit}
            sx={{
              maxWidth: 550,
              width: "100%",
              // alignSelf:"center",
              marginTop: "24px",
              margin:"auto",
              // background:"red",
            }}
          >
            <Stack spacing={2}>
              <TextField
                fullWidth
                id="outlined-basic1"
                label={t("nome")}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                required
              />
              <TextField
                fullWidth
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                id="outlined-basic2"
                label={t("cognome")}
                variant="outlined"
                required
              />
              <TextField
                fullWidth
                id="outlined-basic3"
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                required
              />

              {!props?.isEdit && (
                <>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    id="outlined-basic4"
                    label={t("password")}
                    variant="outlined"
                    required
                  />

                  <TextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="outlined-basic5"
                    label={t("cpassword")}
                    variant="outlined"
                    required
                  />
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {!props?.isEdit && (
                  <Button
                    variant="contained"
                    type="button"
                    onClick={(e) => onSaveAndContinueClicked(e, true)}
                    id="saveAndContinue"
                  >
                    {t("salvaEProsegui")}
                  </Button>
                )}

                <Button
                  variant="contained"
                  type="button"
                  onClick={
                    props?.isEdit
                      ? fnEditUser
                      : (e) => onSaveAndContinueClicked(e, false)
                  }
                  id="saveAndExit"
                >
                  {t("salvaEdESCI")}
                </Button>

                {props?.isEdit && (
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={() => {
                      setOpenDelete(!openDelete);
                    }}
                  // startIcon={<DeleteIcon />}
                  >
                    {t("elimina")}
                  </Button>
                )}

                {/* <Button variant="contained" color="error"
                  onClick={backToList}>
                  {t('indietro')}

                  </Button> */}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : UserModal}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert onClose={handleAlertClose} sx={{color:"#fff"}} severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <DeleteDialog
        open={openDelete}
        handleClose={handleCloseDeleteDialog}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default AddUser;
