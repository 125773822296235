import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Stack, FormGroup, Divider } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "../../../constants/apiStatusCode";
import defaultString from "../../../constants/defaultString.json";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import { API } from "service/AxiosService";
import { useEffect } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";

import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import logo from "../../../../src/assets/image/revisoft_logo.png";

import helveticaNeueBold from "../../../../src/fonts/HelveticaNeuBold.ttf";
import helveticaNeueLight from "../../../../src/fonts/HelveticaNeueLight.ttf";
import CalibriRegular from "../../../../src/fonts/calibriregular.ttf";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { saveAs } from "file-saver";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";
Font.register({
  family: "Helvetica Neue1",
  fonts: [{ src: helveticaNeueLight, fontWeight: "400" }],

  family: "Helvetica Neue",
  fonts: [{ src: helveticaNeueBold, fontWeight: "600" }],
});

Font.register({
  family: "Calibri",
  fonts: [
    { src: CalibriRegular },
    { src: CalibriRegular, fontWeight: "bold" },
    // Add additional variations if needed, like italic, bold italic, etc.
  ],
});

function createData(dataRegistrazione, conto, importoDare, importoAvere, nota) {
  return {
    dataRegistrazione,
    conto,
    importoDare,
    importoAvere,
    nota,
  };
}

const rows = [
  createData(
    "11/01/1991",
    "04 - Conto anticipi",
    "999.999.999,00",
    "999.999.999,00 ",
    "Nota bla baladkf "
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 3 },
        pb: { sm: 3 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            id="outlined-basic"
            label="Cerca"
            variant="outlined"
            sx={{ width: "25pc" }}
          />
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [dense, setDense] = React.useState(false);

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [rowsStato, setRowsStato] = useState([]);
  const [rowsConto, setRowsConto] = useState([]);
  const [startingProgressives, setStartingProgressives] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [clientName, setClientName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [customer, setCustomer] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(t("startDateRequired"))
      .test(
        t("startDateRequired"),
        t("startDateSmallerThanEndDate"),
        function (startDate) {
          const { endDate } = this.parent;
          if (!endDate || !startDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(startDate) <= new Date(endDate);
        }
      ),
    endDate: Yup.date()
      .required(t("endDateRequired"))
      .test(
        t("endDateRequired"),
        t("endDateGreaterThanStartDate"),
        function (endDate) {
          const { startDate } = this.parent;
          if (!startDate || !endDate) {
            return true; // Skip validation if either field is not selected
          }
          return new Date(endDate) >= new Date(startDate);
        }
      ),
  });
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log(values);
      // getMastroContibleReport();
    },
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  // useEffect(() => {
  //   if (formik?.values?.startDate && formik?.values?.endDate) {
  //     getBillanicioReport();
  //   }
  // }, [
  //   formik?.values?.startDate,
  //   formik?.values?.endDate,
  //   isSupplier,
  //   isClient,
  // ]);

  async function calculateTotals(rowsStato, rowsConto) {
    let totalAmountGiveStato = 0;
    let totalAmountHaveStato = 0;
    let totalSaldoStato = 0;

    let totalAmountGiveConto = 0;
    let totalAmountHaveConto = 0;
    let totalSaldoConto = 0;

    rowsStato.forEach((row) => {
      totalAmountGiveStato += row.amountGive;
      totalAmountHaveStato += row.amountHave;
      totalSaldoStato = totalAmountGiveStato - totalAmountHaveStato;
    });
    rowsConto.forEach((row) => {
      totalAmountGiveConto += row.amountGive;
      totalAmountHaveConto += row.amountHave;
      totalSaldoConto = totalAmountGiveConto - totalAmountHaveConto;
    });

    return {
      totalAmountGiveStato: isNaN(totalAmountGiveStato)
        ? 0
        : totalAmountGiveStato,

      totalAmountHaveStato: isNaN(totalAmountHaveStato)
        ? 0
        : totalAmountHaveStato,
      totalSaldoStato: isNaN(totalSaldoStato)
        ? 0
        : parseFloat(totalSaldoStato).toFixed(2),

      totalAmountGiveConto: isNaN(totalAmountGiveConto)
        ? 0
        : totalAmountGiveConto,

      totalAmountHaveConto: isNaN(totalAmountHaveConto)
        ? 0
        : totalAmountHaveConto,

      totalSaldoConto: isNaN(totalSaldoConto)
        ? 0
        : parseFloat(totalSaldoConto).toFixed(2),
    };
  }

  useEffect(() => {
    calculateTotals(rowsStato, rowsConto);
  }, [rowsStato, rowsConto, isSupplier, isClient]);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getBillanicioReport = async () => {
    setIsLoading(true);
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    // if ((selecetdSubAccountId) === "") {
    //   setSeverity("error");
    //   setOpen(true);
    //   setErrorMsg(t("subAccountRequired"));
    //   return;
    // }
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 0,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0,
      };

      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;

      API.post(apiURL.service_audit_report, requestObject)
        .then(async (res) => {
          console.log("res4", res);
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            // setRowsStato(res?.data?.data?.report?.stato_data);
            // setRowsConto(res?.data?.data?.report?.economico_data);
            await generatePDFDocument(
              res?.data?.data?.report?.stato_data,
              res?.data?.data?.report?.economico_data,
              clientName,
              vatNumber
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const getBillanicioReportExcel = async () => {
    setIsLoading(true);
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    // if ((selecetdSubAccountId) === "") {
    //   setSeverity("error");
    //   setOpen(true);
    //   setErrorMsg(t("subAccountRequired"));
    //   return;
    // }
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 1,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0, // Add this parameter
      };
      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;

      API.post(apiURL.service_audit_report, requestObject)
        .then(async (res) => {
          console.log("res4", res);
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            console.log("URL ::::", res?.data?.data?.excelUrl);
            const excelUrl = res?.data?.data?.excelUrl;
            // setRowsStato(res?.data?.data?.report?.stato_data);
            // setRowsConto(res?.data?.data?.report?.economico_data);
            await generatePDFDocumentExcel(
              // res?.data?.data?.report?.stato_data,
              // res?.data?.data?.report?.economico_data,
              // clientName,
              // vatNumber,
              excelUrl
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const getBillanicioReportClientExcel = async () => {
    setIsLoading(true);
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    // if ((selecetdSubAccountId) === "") {
    //   setSeverity("error");
    //   setOpen(true);
    //   setErrorMsg(t("subAccountRequired"));
    //   return;
    // }
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 1,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0,
      };

      // requestObject.subaccountId =
      //   selecetdSubAccountId || selecetdEconomicoSubAccountId;

      API.post(apiURL.service_audit_customer_report, requestObject)
        .then(async (res) => {
          console.log("res4", res);
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            console.log("URL ::::", res?.data?.data?.excelUrl);
            const excelUrl = res?.data?.data?.excelUrl;
            // setRowsStato(res?.data?.data?.report?.stato_data);
            // setRowsConto(res?.data?.data?.report?.economico_data);
            await generatePDFDocumentClientiExcel(
              // res?.data?.data?.report?.stato_data,
              // res?.data?.data?.report?.economico_data,
              // clientName,
              // vatNumber,
              excelUrl
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const getSupplierBillanicioReportExcel = async () => {
    setIsLoading(true);
    // if ((selecetdEconomicoSubAccountId || selecetdSubAccountId) === "") {
    // if ((selecetdSubAccountId) === "") {
    //   setSeverity("error");
    //   setOpen(true);
    //   setErrorMsg(t("subAccountRequired"));
    //   return;
    // }
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 1,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0,
      };

      API.post(apiURL.service_audit_supplier_report, requestObject)
        .then(async (res) => {
          console.log("res4", res);
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            console.log("URL ::::", res?.data?.data?.excelUrl);
            const excelUrl = res?.data?.data?.excelUrl;
            // setRowsStato(res?.data?.data?.report?.stato_data);
            // setRowsConto(res?.data?.data?.report?.economico_data);
            await generateSupplierPDFDocumentExcel(
              // res?.data?.data?.report?.stato_data,
              // res?.data?.data?.report?.economico_data,
              // clientName,
              // vatNumber,
              excelUrl
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };

  const callApiAndPdf = async () => {
    const data = await getBillanicioReport();
  };

  const isDataAvailable = !!rows; // Assuming rowsForPDF is the data for PDF generation
  const isStartDateSelected = !!formik.values.startDate;
  const isEndDateSelected = !!formik.values.endDate;

  const generatePDFDocument = async (
    rowsStato,
    rowsConto,
    clientName,
    vatNumber
  ) => {
    const totals = await calculateTotals(rowsStato, rowsConto);
    const totalAmountGiveStato = totals.totalAmountGiveStato;
    const totalAmountHaveStato = totals.totalAmountHaveStato;
    const totalSaldoStato = totals.totalSaldoStato;
    const totalAmountGiveConto = totals.totalAmountGiveConto;
    const totalAmountHaveConto = totals.totalAmountHaveConto;
    const totalSaldoConto = totals.totalSaldoConto;

    console.log("isClient", isClient);
    console.log("isSupplier", isSupplier);
    // function customSort(a, b) {
    //   function sortSubaccountDetails(detailsA, detailsB) {
    //     const keyA = `${detailsA.type}.${detailsA.accountCode}.${detailsA.code}`;
    //     const keyB = `${detailsB.type}.${detailsB.accountCode}.${detailsB.code}`;

    //     const partsA = keyA.split(".").map((part) => parseInt(part, 10));
    //     const partsB = keyB.split(".").map((part) => parseInt(part, 10));

    //     for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    //       if (partsA[i] !== partsB[i]) {
    //         return partsA[i] - partsB[i];
    //       }
    //     }

    //     return partsA.length - partsB.length;
    //   }

    //   const accountCodeComparison = sortSubaccountDetails(a, b);

    //   if (accountCodeComparison !== 0) {
    //     return accountCodeComparison;
    //   }

    //   return sortSubaccountDetails(a, b);
    // }
    // rowsStato.sort(customSort);
    // rowsConto.sort(customSort);
    const blob = await pdf(
      <Document>
        <Page size="A4" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              {/* <View
                style={{
                  width: "70%",
                }}
              >
                <Image src={logo} style={{ width: "240px" }} />
              </View> */}
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("bilancioDiVerifica")}
                </Text>
              </View>

              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {clientName} - {""}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                  }}
                >
                  {vatNumber}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t("periodo")}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.endDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
              </View>
            </View>

            {/* <View
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "16px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "Helvetica Neue",
                                    fontWeight: "600",
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("statoPatrimoniale")}
                                </Text>
                              </View> */}
          </View>

          {/* Table 1 */}

          <View
            style={{
              marginBottom: "16px",
            }}
          >
            <Text
              style={{
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                fontSize: 10,
                textTransform: "uppercase",
              }}
            >
              {t("statoPatrimoniale")}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottom: "0.5px solid black",
              padding: 2,
            }}
          >
            <Text
              style={{
                width: "55%",
                fontSize: 11,
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("conto")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("dare")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("avere")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("saldo")}
            </Text>
          </View>

          {/* Table 1-details */}

          <View
            style={{
              flexDirection: "column",
              borderBottom: "0.5px solid black",
              width: "100%",
            }}
          >
            {rowsStato.length > 0
              ? rowsStato.map((item, index) => {
                  const sortedSubaccountDetails = [...item.subaccountDetails];
                  return (
                    <View
                      style={{
                        flexDirection: "column",
                        borderBottom: "0.5px solid black",
                        paddingTop: 3,
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          borderBottom: "0.5px solid black",
                          paddingTop: 3,
                          paddingLeft: 2,
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            width: "55%",
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                          }}
                        >
                          {`${item?.type}.${item?.accountCode}  ${item?.accountName}`}
                        </Text>
                        <Text
                          style={{
                            width: "15%",
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {item?.amountGive == 0
                            ? null
                            : item?.amountGive.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        <Text
                          style={{
                            width: "15%",
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {item?.amountHave == 0
                            ? null
                            : item?.amountHave.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Text>
                        <Text
                          style={{
                            width: "15%",
                            fontSize: 10,
                            fontFamily: "Helvetica Neue",
                            fontWeight: "600",
                            textAlign: "right",
                          }}
                        >
                          {item?.balance.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </View>

                      <View>
                        {sortedSubaccountDetails.map((subAccount, i) => {
                          const bal = subAccount?.balance;
                          return (
                            <>
                              {(isClient == 1 &&
                                subAccount?.accountCode == 3) ||
                              (isSupplier == 1 &&
                                subAccount?.accountCode == 51) ? (
                                bal != 0 && (
                                  <View
                                    style={{
                                      width: "100%",
                                      flexDirection: "row",
                                      borderBottom: "0.5px solid black",
                                      paddingTop: 3,
                                      paddingLeft: 2,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "55%",
                                        fontSize: 10,
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {`${subAccount?.type}.${subAccount?.accountCode}.${subAccount.code}  ${subAccount?.name}`}
                                    </Text>
                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {subAccount?.amountGive == 0
                                        ? null
                                        : subAccount?.amountGive.toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                    </Text>
                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {subAccount?.amountHave == 0
                                        ? null
                                        : subAccount?.amountHave.toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                    </Text>

                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {parseFloat(bal).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      {/* {subAccount?.balance == 0
                                  ? null
                                  : subAccount?.balance.toLocaleString(
                                      "de-DE",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )} */}
                                    </Text>
                                  </View>
                                )
                              ) : (
                                <View
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    borderBottom: "0.5px solid black",
                                    paddingTop: 3,
                                    paddingLeft: 2,
                                  }}
                                >
                                  <Text
                                    style={{
                                      width: "55%",
                                      fontSize: 10,
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {`${subAccount?.type}.${subAccount?.accountCode}.${subAccount.code}  ${subAccount?.name}`}
                                  </Text>
                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {subAccount?.amountGive == 0
                                      ? null
                                      : subAccount?.amountGive.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                  </Text>
                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {subAccount?.amountHave == 0
                                      ? null
                                      : subAccount?.amountHave.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                  </Text>

                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {parseFloat(bal).toLocaleString("de-DE", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                    {/* {subAccount?.balance == 0
  ? null
  : subAccount?.balance.toLocaleString(
      "de-DE",
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )} */}
                                  </Text>
                                </View>
                              )}
                            </>
                          );
                        })}
                        {/* <View
                                          style={{
                                            flexDirection: "row",

                                            paddingTop: 3,
                                            paddingLeft: 2,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              width: "55%",
                                              fontSize: 10,
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                        </View> */}
                      </View>
                    </View>
                  );
                })
              : null}
            <View
              style={{
                flexDirection: "row",
                paddingTop: 3,
                paddingLeft: 2,
              }}
            >
              <Text
                style={{
                  width: "55%",
                  fontSize: 10,
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                  textAlign: "right",
                }}
              >
                {t("totalBalanceSheet")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {totalAmountGiveStato == 0
                  ? null
                  : totalAmountGiveStato.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {totalAmountHaveStato == 0
                  ? null
                  : totalAmountHaveStato.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {totalSaldoStato == 0
                  ? null
                  : parseFloat(totalSaldoStato).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
            </View>
          </View>

          <View
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 10 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
        <Page size="A4" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              {/* <View
                style={{
                  width: "70%",
                }}
              >
                <Image src={logo} style={{ width: "240px" }} />
              </View> */}
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("bilancioDiVerifica")}
                </Text>
              </View>

              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {clientName} - {""}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                  }}
                >
                  {vatNumber}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t("periodo")}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.endDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
              </View>
            </View>

            {/* <View
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "16px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "Helvetica Neue",
                                    fontWeight: "600",
                                    fontSize: 10,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("statoPatrimoniale")}
                                </Text>
                              </View> */}
          </View>

          <View>
            {/* Table 2-heading */}

            <View>
              <Text
                style={{
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  fontSize: 10,
                  textTransform: "uppercase",
                }}
              >
                {t("contoEconomico")}
              </Text>
            </View>

            {/* Table 2 */}

            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                borderBottom: "0.5px solid black",
                padding: 2,
              }}
            >
              <Text
                style={{
                  width: "55%",
                  fontSize: 11,
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {t("conto")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {t("dare")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {t("avere")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {t("saldo")}
              </Text>
            </View>

            {/* Table 2-details */}

            <View
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              {
                rowsConto.length > 0
                  ? rowsConto.map((item, index) => {
                      const sortedSubaccountDetails = [
                        ...item.subaccountDetails,
                      ];
                      return (
                        <View
                          style={{
                            flexDirection: "column",
                            borderBottom: "0.5px solid black",
                            paddingTop: 3,
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              borderBottom: "0.5px solid black",
                              paddingTop: 3,
                              paddingLeft: 2,
                              width: "100%",
                            }}
                          >
                            <Text
                              style={{
                                width: "55%",
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                              }}
                            >
                              {`${item?.type}.${item?.accountCode}  ${item?.accountName}`}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                textAlign: "right",
                              }}
                            >
                              {item?.amountGive == 0
                                ? null
                                : item?.amountGive.toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                textAlign: "right",
                              }}
                            >
                              {item?.amountHave == 0
                                ? null
                                : item?.amountHave.toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                fontFamily: "Helvetica Neue",
                                fontWeight: "600",
                                textAlign: "right",
                              }}
                            >
                              {item?.balance.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Text>
                          </View>

                          <View>
                            {sortedSubaccountDetails.map((subAccount, i) => {
                              const bal = subAccount?.balance;

                              // console.log("isClient ::: 111111", isClient);
                              // console.log("bal ::: !0000", bal);

                              return (
                                <>
                                  <View
                                    style={{
                                      width: "100%",
                                      flexDirection: "row",
                                      borderBottom: "0.5px solid black",
                                      paddingTop: 3,
                                      paddingLeft: 2,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        width: "55%",
                                        fontSize: 10,
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {`${subAccount?.type}.${subAccount?.accountCode}.${subAccount.code}  ${subAccount?.name}`}
                                    </Text>
                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {subAccount?.amountGive == 0
                                        ? null
                                        : subAccount?.amountGive.toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                    </Text>
                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {subAccount?.amountHave == 0
                                        ? null
                                        : subAccount?.amountHave.toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                    </Text>
                                    <Text
                                      style={{
                                        width: "15%",
                                        fontSize: 10,
                                        textAlign: "right",
                                        fontFamily: "Calibri",
                                      }}
                                    >
                                      {subAccount?.balance.toLocaleString(
                                        "de-DE",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </Text>
                                  </View>
                                </>
                              );
                            })}
                            {/* <View
                                          style={{
                                            flexDirection: "row",
                                            paddingTop: 3,
                                            paddingLeft: 2,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              width: "55%",
                                              fontSize: 10,
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                          <Text
                                            style={{
                                              width: "15%",
                                              fontSize: 10,
                                              textAlign: "center",
                                              fontFamily: "Calibri",
                                              minHeight: "10px",
                                            }}
                                          ></Text>
                                        </View> */}
                          </View>
                        </View>
                      );
                    })
                  : null
                // <View style={{ width: "100%" }}>
                //   <Text
                //     style={{
                //       borderBottom: "0.5px solid black",
                //       borderTop: "0px solid black",
                //       fontSize: 12,
                //       textAlign: "center",
                //       padding: 2,
                //       minHeight: "16px",
                //     }}
                //   ></Text>
                // </View>
              }
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "0.5px solid black",
                  paddingTop: 3,
                  paddingLeft: 2,
                }}
              >
                <Text
                  style={{
                    width: "55%",
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    minHeight: "10px",
                    textAlign: "right",
                  }}
                >
                  {t("totalIncomeAccount")}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    minHeight: "10px",
                  }}
                >
                  {totalAmountGiveConto == 0
                    ? null
                    : totalAmountGiveConto.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    minHeight: "10px",
                  }}
                >
                  {totalAmountHaveConto == 0
                    ? null
                    : totalAmountHaveConto.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",
                    fontWeight: "600",
                    fontFamily: "Helvetica Neue",
                    minHeight: "10px",
                  }}
                >
                  {totalSaldoConto == 0
                    ? null
                    : parseFloat(totalSaldoConto).toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </View>

              {/* No record line */}

              {/* <View
                                style={{
                                  flexDirection: "row",
                                  borderBottom: "0.5px solid black",
                                  paddingTop: 3,
                                  paddingLeft: 2,
                                }}
                              >
                                <Text
                                  style={{
                                    width: "55%",
                                    fontSize: 10,
                                    fontFamily: "Calibri",
                                    minHeight: "10px",
                                  }}
                                ></Text>
                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "center",
                                    fontFamily: "Calibri",
                                    minHeight: "10px",
                                  }}
                                ></Text>
                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "center",
                                    fontFamily: "Calibri",
                                    minHeight: "10px",
                                  }}
                                ></Text>
                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "center",
                                    fontFamily: "Calibri",
                                    minHeight: "10px",
                                  }}
                                ></Text>
                              </View> */}

              {/* Final Total */}

              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 3,
                  paddingLeft: 2,
                  borderBottom: "0.5px solid black",
                }}
              >
                <Text
                  style={{
                    width: "55%",
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    minHeight: "10px",
                    textAlign: "right",
                    textTransform: "uppercase",
                  }}
                >
                  {t("squadratura")}
                </Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",

                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    minHeight: "10px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    minHeight: "10px",
                  }}
                ></Text>
                <Text
                  style={{
                    width: "15%",
                    fontSize: 10,
                    textAlign: "right",
                    fontWeight: "600",
                    fontFamily: "Helvetica Neue",
                    minHeight: "10px",
                    color:
                      checkAndReturnStatus(totalSaldoStato, totalSaldoConto) ===
                      "OK"
                        ? "#4caf50"
                        : "#EB5353",
                  }}
                >
                  {checkAndReturnStatus(totalSaldoStato, totalSaldoConto)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 10 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);

    saveAs(blob, "Bilancio_di_verifica");
  };

  const generatePDFDocumentExcel = async (excelUrl) => {
    console.log(excelUrl, "excelUrl");
    setIsLoading(true);

    try {
      // Fetch the Excel file
      const excelResponse = await fetch(apiURL.siteBaseUrl + excelUrl);

      if (!excelResponse.ok) {
        throw new Error(`Failed to fetch Excel file: ${excelUrl}`);
      }

      // Read the Excel content
      const excelData = await excelResponse.blob();

      // Create a blob and create an object URL for it
      const excelBlob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelObjectURL = URL.createObjectURL(excelBlob);

      // Create a link element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = excelObjectURL;
      downloadLink.download = "Bilancio_di_Verifica.xlsx";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Clean up the link element and object URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(excelObjectURL);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePDFDocumentClientiExcel = async (excelUrl) => {
    console.log(excelUrl, "excelUrl");
    setIsLoading(true);

    try {
      // Fetch the Excel file
      const excelResponse = await fetch(apiURL.siteBaseUrl + excelUrl);

      if (!excelResponse.ok) {
        throw new Error(`Failed to fetch Excel file: ${excelUrl}`);
      }

      // Read the Excel content
      const excelData = await excelResponse.blob();

      // Create a blob and create an object URL for it
      const excelBlob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelObjectURL = URL.createObjectURL(excelBlob);

      // Create a link element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = excelObjectURL;
      downloadLink.download = "elenco_clienti.xlsx";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Clean up the link element and object URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(excelObjectURL);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateSupplierPDFDocumentExcel = async (excelUrl) => {
    console.log(excelUrl, "excelUrl");
    setIsLoading(true);

    try {
      // Fetch the Excel file
      const excelResponse = await fetch(apiURL.siteBaseUrl + excelUrl);

      if (!excelResponse.ok) {
        throw new Error(`Failed to fetch Excel file: ${excelUrl}`);
      }

      // Read the Excel content
      const excelData = await excelResponse.blob();

      // Create a blob and create an object URL for it
      const excelBlob = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelObjectURL = URL.createObjectURL(excelBlob);

      // Create a link element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = excelObjectURL;
      downloadLink.download = "elenco_fornitori.xlsx";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Clean up the link element and object URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(excelObjectURL);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function checkAndReturnStatus(num1, num2) {
    // console.log("numbers:: ", num1, num2, Math.abs(num1), Math.abs(num2))
    if (
      // Math.abs(num1) === Math.abs(num2)
      // &&
      parseFloat(num1) + parseFloat(num2) ===
      0
    ) {
      return "OK";
    } else {
      return t("error");
    }
  }

  const getBillanicioClientiReport = async () => {
    setIsLoading(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 0,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0,
      };

      API.post(apiURL.service_audit_customer_report, requestObject)
        .then(async (res) => {
          console.log("res4", res);
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            setCustomer(res?.data?.data?.report?.customer_data);
            await generatePDFDocumentClienti(
              res?.data?.data?.report?.customer_data,
              clientName,
              vatNumber
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };
  const callApiAndPdfClienti = async () => {
    const data = await getBillanicioClientiReport();
  };
  const generatePDFDocumentClienti = async (
    customer,
    clientName,
    vatNumber
  ) => {
    // Pre-calcolare i totali
    let ttlAmountGive = 0;
    let ttlAmountHave = 0;
    let ttlAmountBalance = 0;

    // Calcolo dei totali prima della generazione del PDF
    customer.forEach((item) => {
      item.subaccountDetails.forEach((subaccount) => {
        if (isClient ? subaccount.balance !== 0 : true) {
          ttlAmountGive += Number(subaccount.amountGive || 0);
          ttlAmountHave += Number(subaccount.amountHave || 0);
        }
      });
    });

    // Calcolare e arrotondare i totali finali
    ttlAmountGive = parseFloat(ttlAmountGive.toFixed(2));
    ttlAmountHave = parseFloat(ttlAmountHave.toFixed(2));
    ttlAmountBalance = parseFloat((ttlAmountGive - ttlAmountHave).toFixed(2));

    const blob = await pdf(
      <Document>
        <Page size="A4" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("Elenco Clienti")}
                </Text>
              </View>

              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {clientName} - {""}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                  }}
                >
                  {vatNumber}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t("periodo")}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.endDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottom: "0.5px solid black",
              padding: 2,
            }}
          >
            <Text
              style={{
                width: "55%",
                fontSize: 11,
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("conto")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("dare")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("avere")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("saldo")}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "column",
              borderBottom: "0.5px solid black",
              width: "100%",
            }}
          >
            {customer &&
              customer.map((item, subIndex) => (
                <View
                  key={subIndex}
                  style={{
                    flexDirection: "column",
                    borderBottom: "0.5px solid black",
                    paddingTop: 3,
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      borderBottom: "0.5px solid black",
                      paddingTop: 3,
                      paddingLeft: 2,
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        width: "55%",
                        fontSize: 10,
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                      }}
                    >
                      {`${item.type}.${item.accountCode} ${item.accountName}`}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 10,
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {item.amountGive === 0
                        ? null
                        : item.amountGive.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 10,
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {item.amountHave === 0
                        ? null
                        : item.amountHave.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 10,
                        fontFamily: "Helvetica Neue",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {item.balance.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </View>

                  <View>
                    {item.subaccountDetails.map((subaccount, index) => {
                      return isClient ? (
                        subaccount?.balance != 0 && (
                          <View
                            key={index}
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              borderBottom: "0.5px solid black",
                              paddingTop: 3,
                              paddingLeft: 2,
                            }}
                          >
                            <Text
                              style={{
                                width: "55%",
                                fontSize: 10,
                                fontFamily: "Calibri",
                              }}
                            >
                              {`${subaccount?.type}.${subaccount?.accountCode}.${subaccount.code}  ${subaccount?.name}`}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                textAlign: "right",
                                fontFamily: "Calibri",
                              }}
                            >
                              {subaccount?.amountGive == 0
                                ? null
                                : subaccount?.amountGive.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                textAlign: "right",
                                fontFamily: "Calibri",
                              }}
                            >
                              {subaccount?.amountHave == 0
                                ? null
                                : subaccount?.amountHave.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Text>
                            <Text
                              style={{
                                width: "15%",
                                fontSize: 10,
                                textAlign: "right",
                                fontFamily: "Calibri",
                              }}
                            >
                              {parseFloat(subaccount?.balance).toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Text>
                          </View>
                        )
                      ) : (
                        <View
                          key={index}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            borderBottom: "0.5px solid black",
                            paddingTop: 3,
                            paddingLeft: 2,
                          }}
                        >
                          <Text
                            style={{
                              width: "55%",
                              fontSize: 10,
                              fontFamily: "Calibri",
                            }}
                          >
                            {`${subaccount?.type}.${subaccount?.accountCode}.${subaccount.code}  ${subaccount?.name}`}
                          </Text>
                          <Text
                            style={{
                              width: "15%",
                              fontSize: 10,
                              textAlign: "right",
                              fontFamily: "Calibri",
                            }}
                          >
                            {subaccount?.amountGive == 0
                              ? null
                              : subaccount?.amountGive.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                          <Text
                            style={{
                              width: "15%",
                              fontSize: 10,
                              textAlign: "right",
                              fontFamily: "Calibri",
                            }}
                          >
                            {subaccount?.amountHave == 0
                              ? null
                              : subaccount?.amountHave.toLocaleString("de-DE", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Text>
                          <Text
                            style={{
                              width: "15%",
                              fontSize: 10,
                              textAlign: "right",
                              fontFamily: "Calibri",
                            }}
                          >
                            {parseFloat(subaccount.balance).toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              ))}

            <View
              style={{
                flexDirection: "row",
                paddingTop: 3,
                paddingLeft: 2,
              }}
            >
              <Text
                style={{
                  width: "55%",
                  fontSize: 10,
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                  textAlign: "right",
                }}
              >
                {t("totale")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountGive == 0
                  ? null
                  : ttlAmountGive.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountHave == 0
                  ? null
                  : ttlAmountHave.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountBalance == 0
                  ? null
                  : ttlAmountBalance.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 10 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);

    saveAs(blob, "Elenco_Clienti");
  };

  const getBillanicioSupplierReport = async () => {
    setIsLoading(true);
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const clientName = accountingSubject.name;
    const vatNumber = accountingSubject.vatNumber;
    if (!!formik?.values) {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {
        accountingSubjectId: accountingSubject?._id,
        startDate: dayjs(formik?.values?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(formik?.values?.endDate).format("YYYY-MM-DD"),
        isExcel: 0,
        isClient: isClient ? 1 : 0,
        isSupplier: isSupplier ? 1 : 0,
        startingProgressives: startingProgressives ? 1 : 0,
      };

      API.post(apiURL.service_audit_supplier_report, requestObject)
        .then(async (res) => {
          console.log("res4", JSON.stringify(res));
          if (res?.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            // setCustomer(res?.data?.data?.report?.customer_data);
            await generatePDFDocumentSupplier(
              res?.data?.data?.report?.supplier_data,
              clientName,
              vatNumber
            );
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setOpen(false);
          setSeverity("error");
          setIsLoading(false);
          if (error?.code === defaultString.network_error) {
            setOpen(true);
            setErrorMsg(t("common.networkError"));
          } else if (error?.code === defaultString.bad_request) {
            if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
              setOpen(true);
              setErrorMsg(t("noRecordFound"));
            } else if (
              error?.response?.data?.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
        });
    }
  };
  const callApiAndPdfSupplier = async () => {
    const data = await getBillanicioSupplierReport();
  };

  const generatePDFDocumentSupplier = async (
    supplier,
    clientName,
    vatNumber
  ) => {
    let ttlAmountGive = 0;
    let ttlAmountHave = 0;
    let ttlAmountBalance = 0;

    // Calcolo dei totali prima della generazione del PDF
    supplier.forEach((item) => {
      item.subaccountDetails.forEach((subaccount) => {
        if (isSupplier ? subaccount.balance !== 0 : true) {
          ttlAmountGive += Number(subaccount.amountGive || 0);
          ttlAmountHave += Number(subaccount.amountHave || 0);
        }
      });
    });

    // Calcolare e arrotondare i totali finali
    ttlAmountGive = parseFloat(ttlAmountGive.toFixed(2));
    ttlAmountHave = parseFloat(ttlAmountHave.toFixed(2));
    ttlAmountBalance = parseFloat((ttlAmountGive - ttlAmountHave).toFixed(2));

    const blob = await pdf(
      <Document>
        <Page size="A4" style={{ padding: "24px" }}>
          <View fixed>
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {t("Elenco Fornitori")}
                </Text>
              </View>

              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    marginRight: "16px",
                    alignSelf: "baseline",
                  }}
                >
                  {t("printDate")}
                </Text>

                <Text
                  style={{
                    fontSize: "10px",
                    alignSelf: "baseline",
                  }}
                >
                  {dayjs().format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("soggetto") + " " + t("contabile")} :{" "}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {clientName} - {""}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: "10px",
                  }}
                >
                  {vatNumber}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontSize: 9,
                    fontFamily: "Helvetica Neue",
                    fontWeight: "600",
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t("periodo")}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    border: "0.5px solid black",
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    alignSelf: "center",
                    borderLeft: "none",
                  }}
                >
                  {formik?.values?.startDate
                    ? dayjs(formik?.values?.endDate).format("DD/MM/YYYY")
                    : "DD/MM/YYYY"}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              borderBottom: "0.5px solid black",
              padding: 2,
            }}
          >
            <Text
              style={{
                width: "55%",
                fontSize: 11,
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("conto")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("dare")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("avere")}
            </Text>
            <Text
              style={{
                width: "15%",
                fontSize: 10,
                textAlign: "right",
                fontFamily: "Helvetica Neue",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("saldo")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              borderBottom: "0.5px solid black",
              width: "100%",
            }}
          >
            {supplier &&
              supplier.map((item, subIndex) => {
                return (
                  <View
                    style={{
                      flexDirection: "column",
                      borderBottom: "0.5px solid black",
                      paddingTop: 3,
                      width: "100%",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "0.5px solid black",
                        paddingTop: 3,
                        paddingLeft: 2,
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          width: "55%",
                          fontSize: 10,
                          fontFamily: "Helvetica Neue",
                          fontWeight: "600",
                        }}
                      >
                        {`${item.type}.${item.accountCode} ${item.accountName}`}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 10,
                          fontFamily: "Helvetica Neue",
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        {item.amountGive === 0
                          ? null
                          : item.amountGive.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 10,
                          fontFamily: "Helvetica Neue",
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        {item.amountHave === 0
                          ? null
                          : item.amountHave.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 10,
                          fontFamily: "Helvetica Neue",
                          fontWeight: "600",
                          textAlign: "right",
                        }}
                      >
                        {item.balance.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </View>

                    <View>
                      <>
                        {item.subaccountDetails.map((subaccount, index) => {
                          return isSupplier ? (
                            subaccount?.balance != 0 && (
                              <>
                                <View
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    borderBottom: "0.5px solid black",
                                    paddingTop: 3,
                                    paddingLeft: 2,
                                  }}
                                  key={index}
                                >
                                  <Text
                                    style={{
                                      width: "55%",
                                      fontSize: 10,
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {`${subaccount?.type}.${subaccount?.accountCode}.${subaccount.code}  ${subaccount?.name}`}
                                  </Text>
                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {subaccount?.amountGive == 0
                                      ? null
                                      : subaccount?.amountGive.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                  </Text>
                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {subaccount?.amountHave == 0
                                      ? null
                                      : subaccount?.amountHave.toLocaleString(
                                          "de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                  </Text>

                                  <Text
                                    style={{
                                      width: "15%",
                                      fontSize: 10,
                                      textAlign: "right",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {parseFloat(
                                      subaccount.balance
                                    ).toLocaleString("de-DE", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Text>
                                </View>
                              </>
                            )
                          ) : (
                            <>
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  borderBottom: "0.5px solid black",
                                  paddingTop: 3,
                                  paddingLeft: 2,
                                }}
                                key={index}
                              >
                                <Text
                                  style={{
                                    width: "55%",
                                    fontSize: 10,
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  {`${subaccount?.type}.${subaccount?.accountCode}.${subaccount.code}  ${subaccount?.name}`}
                                </Text>
                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "right",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  {subaccount?.amountGive == 0
                                    ? null
                                    : subaccount?.amountGive.toLocaleString(
                                        "de-DE",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                </Text>
                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "right",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  {subaccount?.amountHave == 0
                                    ? null
                                    : subaccount?.amountHave.toLocaleString(
                                        "de-DE",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                </Text>

                                <Text
                                  style={{
                                    width: "15%",
                                    fontSize: 10,
                                    textAlign: "right",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  {parseFloat(
                                    subaccount.balance
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Text>
                              </View>
                            </>
                          );
                        })}
                      </>
                    </View>
                  </View>
                );
              })}

            <View
              style={{
                flexDirection: "row",
                paddingTop: 3,
                paddingLeft: 2,
              }}
            >
              <Text
                style={{
                  width: "55%",
                  fontSize: 10,
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                  textAlign: "right",
                }}
              >
                {t("totale")}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountGive == 0
                  ? null
                  : ttlAmountGive.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountHave == 0
                  ? null
                  : ttlAmountHave.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
              <Text
                style={{
                  width: "15%",
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "600",
                  minHeight: "10px",
                }}
              >
                {ttlAmountBalance == 0
                  ? null
                  : parseFloat(ttlAmountBalance).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
            }}
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 10 }}>
                Pagina {pageNumber} di {totalPages}
              </Text>
            )}
            fixed
          />
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);

    saveAs(blob, "Elenco_Fornitori");
  };
  // console.log("isClient", isClient);

  const isReportButtonEnabled = () => {
    // Check if both dates have the same year
    return (
      formik.values.startDate &&
      formik.values.endDate &&
      new Date(formik.values.startDate).getFullYear() ===
        new Date(formik.values.endDate).getFullYear()
    );
  };

  // console.log("isReportButtonEnabled:: ", isReportButtonEnabled());

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "48px" }}>
        {isLoading && (
          <Box>
            <LoadingSpinner></LoadingSpinner>
          </Box>
        )}
        <Box>
          <Typography variant="h3">{t("bilancioDiVerifica")}</Typography>
          <Divider />
        </Box>
        <Paper sx={{ width: "100%", mt: 8, padding: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableBody>
                <TableRow display="flex">
                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "12%" }}
                  >
                    <Typography mr={2} sx={{ textTransform: "capitalize" }}>
                      {t("selezionePeriodo")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "22%" }}
                  >
                    <TableCell sx={{ borderBottom: "0px" }}>
                      <TextField
                        // sx={{ marginRight: "24px" }}
                        name="startDate"
                        type="date"
                        variant="standard"
                        size="small"
                        value={formik.values.startDate}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                        }
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                      />
                    </TableCell>

                    <TableCell sx={{ borderBottom: "0px" }}>
                      <TextField
                        name="endDate"
                        size="small"
                        type="date"
                        variant="standard"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                        }
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                      />
                    </TableCell>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "none", padding: "0", width: "24%" }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t("noStartingProgressives")}
                      checked={startingProgressives}
                      onChange={(e) => {
                        setStartingProgressives(e.target.checked);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t("dettaglioClienti")}
                      checked={isClient}
                      onChange={(e) => {
                        const isClientiChecked = e.target.checked
                          ? true
                          : false;
                        setIsClient(isClientiChecked);
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t("dettaglioFornitori")}
                      checked={isSupplier}
                      onChange={(e) => {
                        const isSupplierChecked = e.target.checked
                          ? true
                          : false;
                        setIsSupplier(isSupplierChecked);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ borderBottom: "none", padding: "0" }}>
                    {/* <Button startIcon={<PrintIcon />} variant="contained"
                        color="primary" size='large'>{t('stampa')}</Button> */}

                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<LocalPrintshopIcon />}
                      onClick={() => callApiAndPdf("doc name")}
                      disabled={
                        !isDataAvailable ||
                        !isStartDateSelected ||
                        !isEndDateSelected ||
                        !isReportButtonEnabled()
                      }
                    >
                      {t("stampa")}
                    </Button>

                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<RiFileExcel2Fill />}
                      sx={{ ml: 2 }}
                      disabled={
                        !isDataAvailable ||
                        !isStartDateSelected ||
                        !isEndDateSelected ||
                        !isReportButtonEnabled()
                      }
                      onClick={() => getBillanicioReportExcel()}
                    >
                      {t("Excel")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 3, display: "flex", width: "100%" }}>
            <Box sx={{ borderBottom: "none" }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<LocalPrintshopIcon />}
                onClick={() => callApiAndPdfClienti("doc name")}
                disabled={
                  !isStartDateSelected ||
                  !isEndDateSelected ||
                  !isReportButtonEnabled()
                }
              >
                {t("stampa") + " " + t("clienti")}
              </Button>
            </Box>

            <Box sx={{ borderBottom: "0px", paddingLeft: "16px" }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<RiFileExcel2Fill />}
                disabled={
                  !isStartDateSelected ||
                  !isEndDateSelected ||
                  !isReportButtonEnabled()
                }
                onClick={() => getBillanicioReportClientExcel()}
              >
                {t("excel") + " " + t("clienti")}
              </Button>
            </Box>

            <Box sx={{ borderBottom: "0px", paddingLeft: 2 }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<LocalPrintshopIcon />}
                disabled={
                  !isStartDateSelected ||
                  !isEndDateSelected ||
                  !isReportButtonEnabled()
                }
                onClick={() => callApiAndPdfSupplier("doc name")}
              >
                {t("stampa") + " " + t("fornitori")}
              </Button>
            </Box>

            <Box sx={{ borderBottom: "none", paddingLeft: 2 }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<RiFileExcel2Fill />}
                disabled={
                  !isStartDateSelected ||
                  !isEndDateSelected ||
                  !isReportButtonEnabled()
                }
                onClick={() => getSupplierBillanicioReportExcel()}
              >
                {t("excel") + " " + t("fornitori")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
