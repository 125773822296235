/***
 @description
 */
import React, {useState} from 'react';
import {
  Box, Button, Modal, Typography, Divider, TextField, Stack,
  IconButton, InputAdornment, Container
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const DeleteDialog = (props) => {
  const { i18n, t } = useTranslation();

  return (
      <Modal
          sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          open={props.open}
          // onClose={props.handleClose}
      >

        <Box sx={{
          backgroundColor: 'white', padding: 2, borderRadius: 2,
          width: {
            xs: '90%',
            sm: '90%',
            md: '70%',
            lg: '50%',
            xl: '40%',
          },
        }}>
          <Stack
              spacing={2}>
            <Typography variant="h5" sx={{
              color: 'black',
            }}>
              {t('deleteTitle')+" "+"?"}
            </Typography>

            <Typography variant="body1" sx={{
              color: 'black'
            }}>
              {/* Una volta confermato non sarà più possibile tornare indietro! */}
              {t('deleteMessage')}

            </Typography>

            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <Button onClick={() => {
                  props.handleClose()
                }}>{t('annula')}</Button>
              {/* <Button onClick={() => props.onConfirm}>{t('conferma')}</Button> */}
              <Button onClick={props.onConfirm}>{t('conferma')}</Button>
            </Box>
          </Stack>
        </Box>


      </Modal>
  )

}

export default DeleteDialog;
