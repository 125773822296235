import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Table,
  TableContainer,
  Divider,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";

const MessageDialog = (props) => {
  const { t } = useTranslation();

  // Helper function to determine if error type is a warning
  const isWarning = (errorType) => {
    const warningTypes = [
      "country_err",
      "fiscal_code_warning",
      "vat_number_warning",
      "successwarning"
    ];
    return warningTypes.includes(errorType);
  };

  // Helper function to get translated error message
  const getErrorMessage = (errorType) => {
    const errorMessages = {
      "incorrect_sequance_invoiceno_err": t("incorrectSequence"),
      "invoiceno_err": t("invoiceNumberAlreadyExistincorrect"),
      "country_err": t("countryIsNotIT"),
      "subject_err": t("acccountSubjectMismatch"),
      "document_err": t("documentDateMismatch"),
      "year_err": t("yearIsClosed"),
      "incorrect_invoiceno_err": t("incorrectInvoice"),
      "fiscal_code_warning": t("fiscal_code_warning"),
      "vat_number_warning": t("vat_number_warning")
    };
    return t(errorMessages[errorType] || errorType);
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::-webkit-scrollbar": {
          width: "16px",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#fff",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#4d96ff",
          borderRadius: "10px",
          border: "4px solid #ffffff",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#2c70cf",
        },
      }}
      open={props.open}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          width: {
            xs: "90%",
            sm: "90%",
            md: "70%",
            lg: "50%",
            xl: "40%",
          },
        }}
      >
        {props.filesData && props.filesData.map((item, index) => (
          <Box key={index}>
            <TableContainer
              sx={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: 2,
                "&::-webkit-scrollbar": {
                  width: "0.5em",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#4d96ff",
                  borderRadius: "10px",
                },
              }}
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 700 }}>
                          Importa XML Fatture Vendita
                        </Typography>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={props.handleClose}
                        >
                          {t("close")}
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ top: 44 }}>{t("filename")}</TableCell>
                    <TableCell style={{ top: 44 }}>{t("errorMessage")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(item.invoice).map((fileName) => (
                    <TableRow key={fileName}>
                      <TableCell>{fileName}</TableCell>
                      <TableCell>
                        {item.invoice[fileName].map((error, errorIndex) => (
                          <Box key={errorIndex} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '4px'
                          }}>
                            {isWarning(error.errType) ? (
                              <WarningIcon sx={{ color: "#FFA500" }} />
                            ) : (
                              <ErrorIcon sx={{ color: "#ff0000" }} />
                            )}
                            <Typography pl="8px" variant="body1">
                              {getErrorMessage(error.errType)}
                            </Typography>
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* New Customers Section */}
            {item.newCustomers && item.newCustomers.length > 0 && (
              <>
                <Divider sx={{ my: 2 }} />
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography sx={{ fontWeight: 700 }}>
                            {t("newCustomers")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("filename")}</TableCell>
                        <TableCell>{t("customerName")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.newCustomers.map((customer, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{customer.fileName}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <PersonAddIcon sx={{ color: "#4CAF50", mr: 1 }} />
                              <Typography>{customer.customerName} - {customer.vatNumber}</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default MessageDialog;