/***
 @description
 */

export const saveDataToStorageWithKey = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromStorage = (key) => {
  return new Promise((resolve, reject) => {
     let data  = localStorage.getItem(key)

    if(data) {
      // console.log("If")
      resolve(JSON.parse(data));
    } else {
      // console.log("else")
      resolve(false);
    }
  });
};
