import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Autocomplete, InputAdornment, Stack } from "@mui/material";
import { FormGroup } from "@mui/material";
import { revisoftLogo, welcomeImage } from "../../../../assets/image";
import AddFattureReverseCharge from "screens/fatture/fattureReverseCharge/Add/AddFattureReverseCharge";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import defaultString from "constants/defaultString.json";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getDataFromStorage } from "storage/StorageData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { AddFattureAcquisto } from "../Add";
import { Clear, Search } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const documentList = [
  {
    type: 1,
    name: "Nota di Credito",
  },
  {
    type: 2,
    name: "Fattura",
  },
];

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleRemoveSearch,
    setRows,
    originalData,
    searchNumeroFattura,
    searchDataReg,
    searchDataFattura,
    searchPartitaIva,
    setSearchNumeroFattura,
    setSearchDataFattura,
    setSearchDataReg,
    setSearchPartitaIva,
    searchProtocol,
    setSearchProtocol,
    listOfSupplier,
    setListOfSupplier,
    supplier,
    setSupplier,
    inputSupplier,
    setInputSupplier,
    setIsSearch,
    isSearch,
    searchInvoices,
    fnGetInvoices,
    page,
    limit,
    setPage,
  } = props;

  return (
    <Toolbar
      sx={{
        pt: { sm: 1 },
        pb: { sm: 1 },
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionato
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selezionati
        </Typography>
      ) : (
        //         <Typography>
        //         <TextField
        // size='small'
        // id="outlined-basic"
        // label={t("cerca")}
        // placeholder={
        //   t("protocol") +
        //   ", " +
        //   t("fornitore") +
        //   ", " +
        //   t("partita") +
        //   " " +
        //   t("iva")
        // }
        // variant="outlined"
        // sx={{ width: "25pc" }}
        // onChange={props.handleSearch}
        // value={props.searchContent}
        // />
        // </Typography>
        <Typography
          sx={{ flex: "1 1 100%", display: "flex", alignItems: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <TextField
            size="small"
            id="outlined-select-currency"
            // select
            label={t("prot")}
            sx={{
              width: "5pc",
              marginLeft: "8px",
            }}
            value={searchProtocol}
            onChange={(e) => setSearchProtocol(e.target.value)}
          />

          <TextField
            size="small"
            id="input-with-icon-textfield"
            label={"*" + t("data") + " " + t("reg")}
            // placeholder="gg/mm/aaaa"

            type="date"
            name="regDate"
            sx={{ marginLeft: "8px" }}
            value={searchDataReg}
            onChange={(e) => {
              setSearchDataReg(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              ),
              // readOnly: true,
            }}
          />

          <Autocomplete
            size="small"
            value={supplier}
            onChange={(event, newSupplier) => {
              setSupplier(newSupplier);
            }}
            inputValue={inputSupplier}
            onInputChange={(event, newInputSupplier) => {
              setInputSupplier(newInputSupplier);
            }}
            id="controllable-states-demo"
            options={listOfSupplier}
            getOptionLabel={(option) => {
              if (!option) return "";
              return option.subjectTypeId?.type === "Persona Fisica"
                ? `${option.name || ""} ${option.lastName || ""}${
                    option.vatNumber ? ` - ${option.vatNumber}` : ""
                  }`
                : `${option.name || ""}${
                    option.vatNumber ? ` - ${option.vatNumber}` : ""
                  }`;
            }}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return false;
              return option._id === value._id;
            }}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.subjectTypeId?.type === "Persona Fisica"
                  ? `${option.name || ""} ${option.lastName || ""}${
                      option.vatNumber ? ` - ${option.vatNumber}` : ""
                    }`
                  : `${option.name || ""}${
                      option.vatNumber ? ` - ${option.vatNumber}` : ""
                    }`}
              </li>
            )}
            sx={{ width: "20pc", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField size="small" {...params} label={t("fornitori")} />
            )}
          />

          <TextField
            size="small"
            id="outlined-select-currency"
            // select
            label={t("partita") + " " + t("iva")}
            sx={{
              width: "10pc",
              marginLeft: "8px",
            }}
            value={searchPartitaIva}
            onChange={(e) => setSearchPartitaIva(e.target.value)}
          />
          <TextField
            size="small"
            id="outlined-basic"
            sx={{ width: "10pc", marginLeft: "8px" }}
            label={"*" + t("numeroFattura")}
            placeholder="100"
            variant="outlined"
            value={searchNumeroFattura}
            onChange={(e) => setSearchNumeroFattura(e.target.value)}
          />
          <TextField
            size="small"
            id="input-with-icon-textfield"
            label={"*" + t("data") + " " + t("fattura")}
            placeholder="gg/mm/aaaa"
            type="date"
            name="date"
            sx={{
              width: "10pc",
              marginLeft: "8px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              ),
            }}
            value={searchDataFattura}
            onChange={(e) => {
              setSearchDataFattura(e.target.value);
            }}
          />
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={async (e) => {
              setPage(0);
              if (
                searchDataFattura !== "" ||
                searchDataReg !== "" ||
                searchNumeroFattura !== "" ||
                searchPartitaIva !== "" ||
                supplier !== null ||
                searchProtocol !== ""
              ) {
                await searchInvoices(1, limit);
                setIsSearch(true);
              } else {
                fnGetInvoices(1, limit);
              }
            }}
          >
            <Search />
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{
              borderRadius: "5px",
              marginLeft: "8px",
            }}
            onClick={() => handleRemoveSearch()}
          >
            <Clear />
          </Button>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              props.onDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          sx={{
            "&:hover": {
              backgroundColor: "#ffffff",
              boxShadow: "none",
            },
          }}
        >
          <Box sx={{ textAlign: "right" }} width="100%">
            <Button
              sx={{
                marginLeft: "16px",
              }}
              onClick={() => {
                props.onAddOpen();
                // handleRemoveSearch();
                // setRows(originalData);
              }}
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
            >
              {t("registraFatturaAcquisto")}
            </Button>
          </Box>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const FatturePurchaseList = () => {
  const [listOfHeader, setlistOfHeader] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("cognome");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(25);
  const [searchContent, setSearchContent] = useState("");

  const [invoiceObj, setInvoiceObj] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { i18n, t } = useTranslation();
  const [defaultRows, setDefaultRows] = useState(false);
  const [filterOriginal, setFilterOriginal] = useState([]);
  const [searchNumeroFattura, setSearchNumeroFattura] = useState("");
  const [searchDataFattura, setSearchDataFattura] = useState("");
  const [searchDataReg, setSearchDataReg] = useState("");
  const [searchPartitaIva, setSearchPartitaIva] = useState("");
  const [searchProtocol, setSearchProtocol] = useState("");
  const [listOfSupplier, setListOfSupplier] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [inputSupplier, setInputSupplier] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState(0);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const newList = [];
    newList.push({
      id: "protocollo",
      numeric: false,
      disablePadding: true,
      label: t("protocol"),
    });
    newList.push({
      id: "dataRegistrazione",
      numeric: true,
      disablePadding: false,
      label: t("dataRegistrazione"),
    });
    newList.push({
      id: "fornitore",
      numeric: true,
      disablePadding: false,
      label: t("fornitore"),
    });
    newList.push({
      id: "partita",
      numeric: true,
      disablePadding: false,
      label: t("partita") + " " + t("iva"),
    });
    newList.push({
      id: "numeroFattura",
      numeric: true,
      disablePadding: false,
      label: t("numeroFattura"),
    });
    newList.push({
      id: "dataFattura",
      numeric: true,
      disablePadding: false,
      label: t("data") + " " + t("fattura"),
    });
    newList.push({
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    });
    setlistOfHeader(newList);
    getSupplierList();
  }, []);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
      navigate("/", { state: { isModalPopup: true } });
    }
  };

  const getSupplierList = (supplierId = null) => {
    API.get(apiURL.service_get_all_suppliers)
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setListOfSupplier(res?.data?.data?.suppliers);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(false);
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout();
          }
        }
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if (isSearch) {
      await searchInvoices(newPage + 1, limit);
    } else {
      await fnGetInvoices(newPage + 1, limit);
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    let newPage = 0;
    setPage(newPage);
    if (isSearch) {
      await searchInvoices(newPage + 1, newLimit);
    } else {
      await fnGetInvoices(newPage + 1, newLimit);
    }
    setSelected([]);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const fnGetInvoices = useCallback(
    async (page, limit) => {
      setIsLoading(true);
      let accountingSubject = await getDataFromStorage("accountingSubject");

      API.get(
        `${apiURL.service_get_purchase_invoice}?id=${accountingSubject?._id}&page=${page}&limit=${limit}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            console.log("Response = ", JSON.stringify(res.data.data));
            setRows(res.data.data.invoice);
            // setOriginalData(res.data.data.invoice);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              doLogout();
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const searchInvoices = async (pageValue, newLimit) => {
    let accountingSubject = await getDataFromStorage("accountingSubject");
    setIsLoading(true);
    const supplierId = supplier && supplier._id;
    const queryParams = {
      id: accountingSubject?._id,
      page: pageValue,
      limit: newLimit,
    };
    if (searchNumeroFattura) {
      queryParams.invoiceNo = searchNumeroFattura;
    }

    if (searchDataFattura) {
      queryParams.documentDate = dayjs(searchDataFattura).format("YYYY-MM-DD");
    }

    if (searchDataReg) {
      queryParams.registrationDate = dayjs(searchDataReg).format("YYYY-MM-DD");
    }

    if (supplierId) {
      queryParams.supplierId = supplierId;
    }

    if (searchPartitaIva) {
      queryParams.supplierVatNo = searchPartitaIva;
    }
    if (searchProtocol) {
      queryParams.protocol = searchProtocol;
    }

    // Convert the queryParams object to a query string
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
    API.get(`${apiURL.service_filter_purchase_invoice}?${queryString}`)
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setRows(res.data.data.invoice);
          setTotalRecords(res.data.data.totalRecords);
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.NOTFOUND) {
            setOpen(true);
            setErrorMsg(t("noRecordFound"));
          } else if (
            error.response.data.status === apiStatusCode.UNAUTHORIZED
          ) {
            doLogout();
          }
        }
        setRows([]);
        setOriginalData([]);
        setTotalRecords(0);
      });
  };

  const fnDelete = async () => {
    const idsToBeDeleted = JSON.stringify({
      invoiceIds: selected,
    });

    API.post(apiURL.service_delete_purchase_invoice, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          if (isSearch) {
            await searchInvoices(page + 1, limit);
          } else {
            await fnGetInvoices(page + 1, limit);
            handleRemoveSearch();
          }

          // const filteredArr = rows.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setRows(filteredArr);
          // const filteredOriginalArr = filterOriginal.filter(
          //   (item) => !selected.includes(item._id)
          // );
          // setOriginalData(filteredOriginalArr);
          setSelected([]);
        } else {
          setOpen(true);
          setErrorMsg(t("common.internalServerError"));
          setSeverity("error");
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout();
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    console.log("selected:: ", selected);
    await fnDelete();
    setOpenDelete(!openDelete);
  };

  useEffect(() => {
    fnGetInvoices(page + 1, limit);
  }, []);

  const navigateToUpdate = (data) => {
    console.log("Clicked Data = ", JSON.stringify(data));
    setInvoiceObj(data);
    setEdit(true);
    setOpenAddUser(!openAddUser);
    setDefaultRows(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function handleRemoveSearch() {
    setSearchNumeroFattura("");
    setSearchDataFattura("");
    setSearchDataReg("");
    setSearchPartitaIva("");
    setSupplier(null);
    setInputSupplier("");
    setSearchProtocol("");
    setIsSearch(false);
    setTotalRecords(0);
    await fnGetInvoices(page + 1, limit);
  }

  return (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      {isLoading && (
        <Box>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      )}
      <Box>
        <Typography variant="h3" mb={3}>
          {t("fattureAcquisti")}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddOpen={() => {
            setOpenAddUser(!openAddUser);
            setDefaultRows(true);
          }}
          // handleSearch={(event) => {
          //   const search = event.target.value;
          //   setSearchContent(search);
          //   if (search.trim().length > 0) {
          //     const filteredData = filterOriginal.filter(
          //       (item) =>
          //         item.protocol.toString().includes(search.toString()) ||
          //         item.supplierName
          //           .toLowerCase()
          //           .includes(search.toLowerCase()) ||
          //         item.supplierVatNo.toString().includes(search.toString())
          //     );
          //     setRows(filteredData);
          //   } else {
          //     setRows(originalData);
          //   }
          // }}
          onDelete={() => {
            setOpenDelete(!openDelete);
          }}
          // searchContent={searchContent}
          // setSearchContent={setSearchContent}
          handleRemoveSearch={handleRemoveSearch}
          setRows={setRows}
          originalData={originalData}
          setSearchNumeroFattura={setSearchNumeroFattura}
          setSearchDataFattura={setSearchDataFattura}
          setSearchDataReg={setSearchDataReg}
          setSearchPartitaIva={setSearchPartitaIva}
          searchNumeroFattura={searchNumeroFattura}
          searchDataFattura={searchDataFattura}
          searchDataReg={searchDataReg}
          searchPartitaIva={searchPartitaIva}
          searchProtocol={searchProtocol}
          setSearchProtocol={setSearchProtocol}
          listOfSupplier={listOfSupplier}
          setListOfSupplier={setListOfSupplier}
          supplier={supplier}
          setSupplier={setSupplier}
          inputSupplier={inputSupplier}
          setInputSupplier={setInputSupplier}
          setIsSearch={setIsSearch}
          isSearch={isSearch}
          searchInvoices={searchInvoices}
          setPageSearch={setPageSearch}
          pageSearch={pageSearch}
          setTotalRecords={setTotalRecords}
          setRowsPerPage={setRowsPerPage}
          page={page}
          fnGetInvoices={fnGetInvoices}
          setPage={setPage}
          limit={limit}
        />
        <TableContainer
          sx={{
            maxHeight: "70vh",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              data={listOfHeader}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row._id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.protocol}
                    </TableCell>
                    <TableCell align="left">
                      {dayjs(row.registrationDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">{row.supplierName}</TableCell>
                    <TableCell align="left">{row.supplierVatNo}</TableCell>
                    <TableCell align="left">{row.invoiceNo}</TableCell>
                    <TableCell align="left">
                      {dayjs(row.documentDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={() => {
                          navigateToUpdate(row);
                          // handleRemoveSearch();
                          // setRows(originalData);
                        }}
                      >
                        {t("edit")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!isSearch && ( */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("of")} ${count}`
          }
        />
        {/* )} */}
      </Paper>

      <AddFattureAcquisto
        open={openAddUser}
        isEdit={isEdit}
        defaultRows={defaultRows}
        stateObj={invoiceObj}
        handleClose={async (data) => {
          if (data?.reload) {
            if (isSearch) {
              await searchInvoices(page + 1, limit);
            } else {
              await fnGetInvoices(page + 1, limit);
            }
          }
          setEdit(false);
          setInvoiceObj(null);
          setOpenAddUser(!openAddUser);
          setDefaultRows(false);
        }}
      />
      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(!openDelete)}
        onConfirm={confirmDelete}
      />
      <>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            sx={{ color: "#fff" }}
            severity={severity}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </>
    </Box>
  );

  // return (
  //   <>
  //     {isLoading ? LoadingSpinner : InvoiceModel}
  //     <Snackbar
  //       open={open}
  //       autoHideDuration={6000}
  //       onClose={handleAlertClose}
  //       sx={{ width: "100%" }}
  //       anchorOrigin={{ horizontal: "right", vertical: "top" }}
  //     >
  //       <Alert
  //         onClose={handleAlertClose}
  //         sx={{ color: "#fff" }}
  //         severity={severity}
  //       >
  //         {errorMsg}
  //       </Alert>
  //     </Snackbar>
  //   </>
  // );
};

export default FatturePurchaseList;
