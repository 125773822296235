/***
 @description
 */
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box, Button, Modal, Typography, Divider, TextField, Stack,
  IconButton, InputAdornment, Grid, MenuItem
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useFormik} from 'formik';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import {getDataFromStorage} from "storage/StorageData";
import defaultString from "../../../../../constants/defaultString.json";
import {API} from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import {debounce} from "lodash";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import {DeleteDialog} from "components/DeleteDialog";
import MuiAlert from "@mui/material/Alert";
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { resetToken } from 'apiURL/commonFunctions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddSottoConto = (props) => {
  const {i18n, t} = useTranslation();

  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [accountingSubject, setAccountingSubject] = useState(null);
  const [reload, setReload] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [listOfAccounts, setListOfAccounts] = useState([]);
  const [severity,setSeverity]=useState("success")
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  useEffect(() => {
    getDataFromStorage(defaultString.accountingSubject).then((response) => {
      setAccountingSubject(response)
      getAllAccounts(response._id)
    })

  }, [])

  const validationSchema = Yup.object().shape({
    codice: Yup.number()
        .required(t('codice') + " " + t('richiede'))
        .integer('Must be an integer')
        .typeError('Must be a number'),
    sottoConto: Yup.string()
        .required(t('descrizione') + " " + t('richiede')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      account: '',
      codice: '',
      sottoConto: '',
    },
    validationSchema,
    onSubmit: async (values, {setErrors}) => {
      console.log('Form values:', values);

      const requestObject = {}
      let isCodeUnique = false

      requestObject.code = values.codice
      requestObject.accountingSubjectId = accountingSubject?._id
      requestObject.accountId = selectedAccount
      requestObject.type = 1;
      requestObject.subaccountId = props?.isEdit ? props.stateObj?._id : 0;

      const checkCodeResponse = await API.post(apiURL.service_check_subaccount_code_exist, requestObject)
      if (checkCodeResponse && checkCodeResponse?.data?.success) {
        if (checkCodeResponse?.data?.data?.isExist) {
          isCodeUnique = false
          formik?.setFieldTouched('codice', true, false);
          setErrors({
            codice: t('codeIsAlreadyInUser')
          })
        } else {
          isCodeUnique = true
        }
      }

      const requestNameObject = {}
      let isNameUnique = false
      requestNameObject.name = values.sottoConto
      requestNameObject.accountingSubjectId = accountingSubject?._id
      requestNameObject.type = 1
      requestNameObject.subaccountId = props?.isEdit ? props.stateObj?._id : 0;

      const checkNameResponse = await API.post(apiURL.service_check_subaccount_name_exist,requestNameObject)
      if(checkNameResponse && checkNameResponse?.data?.success) {
        if(checkNameResponse?.data?.data?.isExist) {
          isNameUnique = false
          formik?.setFieldTouched('sottoConto', true, false);
          setErrors({
            sottoConto: t('accountNameAlreadyInUse')
          })
        } else {
          isNameUnique = true
        }
      }

      if (isCodeUnique && isNameUnique) {
        await addSottoConto()
      }
    },
  });

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  const fnDeleteAccount = async () => {

    const idsToBeDeleted = JSON.stringify({
      'accountIds': [props.stateObj?._id],
      'accountingSubjectId': accountingSubject?._id
    });

    await API.post(apiURL.service_delete_subaccount, idsToBeDeleted)
        .then(async (res) => {
          if (res.data.status === apiStatusCode.DELETED) {
            setOpen(true);
            setErrorMsg(t("common.deleteSuccess"))
            setSeverity("success")
            formik.resetForm();
            props.handleClose({reload: true});
          }
          else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"))
          }

        }).catch((error) => {
          setIsLoading(false)
          setOpen(true);
          setSeverity("error")
          if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              }
              else{
                setErrorMsg(t("common.badRequest"));
              }
            } 
        });
  }

  const editSottoConto = async () => {

    if (!!formik.values && formik.isValid) {

      let isCodeChanged = false

      if(props?.stateObj?.code !== formik.values.codice) {
        isCodeChanged = true
      }

      let isCodeUnique = true

      if(isCodeChanged) {
        const codeRequestObject = {}
        codeRequestObject.code = formik.values.codice
        codeRequestObject.accountingSubjectId = accountingSubject?._id
        codeRequestObject.accountId = selectedAccount
        codeRequestObject.type = 1;
        codeRequestObject.subaccountId = props?.isEdit ? props.stateObj?._id : 0;

        const checkCodeResponse = await API.post(apiURL.service_check_subaccount_code_exist, codeRequestObject)
        if (checkCodeResponse && checkCodeResponse?.data?.success) {
          if (checkCodeResponse?.data?.data?.isExist) {
            isCodeUnique = false
            formik?.setFieldTouched('codice', true, false);
            formik?.setErrors({
              codice: t('codeIsAlreadyInUser')
            })
          } else {
            isCodeUnique = true
          }
        }
      }

      if(isCodeUnique) {
        const requestObject = {}
        requestObject.code = formik.values.codice ? formik.values.codice : "";
        requestObject.type = 1;
        requestObject.accountingSubjectId = accountingSubject._id;
        requestObject.name = formik.values.sottoConto ? formik.values.sottoConto : "";


        API.put(`${apiURL.service_update_subaccount}/${props.stateObj?._id}`, requestObject).then((response) => {
          setIsLoading(false)
          setReload(true)
          if (response.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setErrorMsg(t("common.updateSuccess"))
            setSeverity("success")
            formik.resetForm();

            props.handleClose({reload: true});
          } else {
            setOpen(true);
            setSeverity("error")
            setErrorMsg(t("common.internalServerError"))
          }

        }).catch((error) => {
          setIsLoading(false)
          setOpen(true)
          setSeverity("error")
          if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            }
            else{
              setErrorMsg(t("common.badRequest"));
            }
          } 
        })
      } else {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (props && props?.stateObj) {
      formik.setValues({
        codice: props?.stateObj?.code ? props?.stateObj?.code : "",
        sottoConto: props?.stateObj?.name ? props?.stateObj?.name : "",
      })
      setSelectedAccount(props?.stateObj?.accountId?._id)
    }
  }, [props, props?.stateObj])

  const backToList = (reload) => {
    props.handleClose({reload: reload});
    formik.resetForm();
    setIsSaveNConti(false);
  }

  const checkCode = useCallback(debounce((value) => {
    const requestObject = {}
    requestObject.code = value
    requestObject.accountingSubjectId = accountingSubject?._id
    requestObject.accountId = selectedAccount
    requestObject.type = 1;
    requestObject.subaccountId = props?.isEdit ? props.stateObj?._id : 0;

    API.post(apiURL.service_check_subaccount_code_exist, requestObject).then(checkCodeResponse => {
      if (checkCodeResponse?.data?.data?.isExist) {
        formik?.setFieldTouched('codice', true, false);
        formik?.setErrors({
          codice: t('codeIsAlreadyInUser')
        })
      } else {
        formik?.setFieldTouched('codice', true, false);
        formik?.setErrors({
          codice: null
        })
      }
    })

  }, 500), []);

  const checkName = useCallback(debounce((value) => {
    const requestObject = {}
    requestObject.name = value
    requestObject.accountingSubjectId = accountingSubject?._id
    requestObject.type = 1
    requestObject.subaccountId = props?.isEdit ? props.stateObj?._id : 0;
    
    API.post(apiURL.service_check_subaccount_name_exist, requestObject).then(checkCodeResponse => {
      if (checkCodeResponse?.data?.data?.isExist) {
        formik?.setFieldTouched('sottoConto', true, false);
        formik?.setErrors({
          sottoConto: t('accountNameAlreadyInUse')
        })
      } else {
        formik?.setFieldTouched('sottoConto', true, false);
        formik?.setErrors({
          sottoConto: null
        })
      }
    })

  }, 500), []);

  const addSottoConto = async () => {

    if (!!formik.values) {
      setIsLoading(true)

      const requestObject = {}
      requestObject.code = formik.values.codice ? formik.values.codice : "";
      requestObject.type = 1;
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.name = formik.values.sottoConto ? formik.values.sottoConto : "";
      requestObject.accountId = selectedAccount;

      API.post(apiURL.service_add_sub_account, requestObject).then((response) => {
        setIsLoading(false)
        setReload(true)
        if (response.status === apiStatusCode.CREATED) {
          setOpen(true);
          setErrorMsg(t("common.addSuccess"))
          setSeverity("success")
          formik.resetForm();

          if (isSaveNConti) {
            setIsSaveNConti(false)
          } else {
            props.handleClose({reload: true});
          }
        }

      }).catch((error) => {
        setIsLoading(false)
        setOpen(true)
        setSeverity("error")
        if (error.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          }
          else{
            setErrorMsg(t("common.badRequest"));
          }
        } 
      })

    }
  }

  const getAllAccounts = async (accountingSubjectId) => {
    setIsLoading(true)
    API.get(`${apiURL.service_get_all_accounts}?type=1&id=${accountingSubjectId}`).then((response) => {
      setIsLoading(false)
      if (response.status === apiStatusCode.SUCCESS) {
        setListOfAccounts(response?.data?.data?.accounts ? response?.data?.data?.accounts : [])
        if (response?.data?.data?.accounts.length > 0) {
          // console.log(JSON.stringify(response?.data?.data?.accounts))
          setTimeout(() => {
            setSelectedAccount(response?.data?.data?.accounts[0]._id)
          }, 500)
        }
      }
    }).catch((error) => {
      setIsLoading(false)
      setOpen(true)
      setSeverity("error")
      if (error?.code === defaultString.network_error) setErrorMsg(t("common.networkError"))
      else if (error?.code === defaultString.bad_request) {
        if (error?.response?.data?.status === apiStatusCode.NOTFOUND) {
          setOpen(false);
        }
        else if (error?.response?.data?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout()
        }
      }
    })

  }

  const handleCodeChange = (event) => {
    formik.handleChange(event);
    if (event.target.value.length > 0) {
      checkCode(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    formik.handleChange(event);
    if (event.target.value.length > 0) {
      checkName(event.target.value);
    }
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    e.preventDefault();
    setIsSaveNConti(isContinue);
    formik.handleSubmit();
  }

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete)
  };

  const handleAlertClose = (event, reason) => {
    setIsLoading(false)
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const confirmDelete = async () => {
    await fnDeleteAccount();
    setOpenDelete(!openDelete)
  }

  const SottoConto = (
      <Modal
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={props.open}
          onClose={() => formik.resetForm()}
      >
        <Box
            sx={{
              width: "70%",
              backgroundColor: "white",
              borderRadius: 2,
            }}
        >
          <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
               
                borderWidth: "1px",
                borderColor: "black",
                borderRadius: 2,
              padding: 1.5,

              }}
          >
            <Typography
                sx={{
                  fontSize: 24,
                  color: "black",
                }}
            >
               {props?.isEdit ? t('changeSottoconto') : t("creaSttoconto")}
            </Typography>

            <Button size='small' variant="contained" onClick={() => {
              backToList(reload)
            }}> {t("indietro")}</Button>
          </Box>
          <Divider/>

          <Box
              sx={{
               
                py: 3,
                height: "65vh",
                overflowY: "auto",
                '&::-webkit-scrollbar': {
                  width: '16px',
                  borderRadius:"10px",
    
                },
                '&::-webkit-scrollbar-track': {
                  background: "#fff",
                  borderRadius:"8px",
    
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#4d96ff',
                  borderRadius:"10px",
                  border: "4px solid #ffffff",
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#2c70cf'
                }
              }}
          >
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                  maxWidth: 550,
                  width: "100%",
                  margin: "auto"

                }}
            >
              <Stack spacing={2}>
                <TextField
                size='small'
                    fullWidth
                    id="outlined-basic"
                    label={t("statoPatrimoniale") + " / " + t("contoEconomico")}
                    name="name"
                    // value={formik.values.name}
                    value={t("statoPatrimoniale")}
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    variant="outlined"
                    required
                />

                <TextField
                size='small'
                    id="outlined-select-currency"
                    select
                    name="account"
                    disabled={props?.isEdit}
                    value={selectedAccount}  // Set the selected account here
                    onChange={(event) => setSelectedAccount(event.target.value)}
                    label={t("selezionaConto")}
                    placeholder={t("selezionaConto")}
                >
                  {
                    listOfAccounts?.map((account, index) => (
                        <MenuItem key={index} value={`${account._id}`}>{`${account?.type}.${account?.code} - ${account?.name}`} </MenuItem>
                    ))
                  }

                </TextField>

                <TextField
                size='small'
                    fullWidth
                    id="outlined-basic"
                    label={t("codice")}
                    name="codice"
                    value={formik.values.codice}
                    onChange={handleCodeChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.codice && Boolean(formik.errors.codice)}
                    helperText={formik.touched.codice && formik.errors.codice}
                    variant="outlined"
                    required
                />
                <TextField
                size='small'
                    fullWidth
                    id="outlined-basic"
                    label={t("descrizione")}
                    name="sottoConto"
                    value={formik.values.sottoConto}
                    onChange={handleNameChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.sottoConto && Boolean(formik.errors.sottoConto)
                    }
                    helperText={
                        formik.touched.sottoConto && formik.errors.sottoConto
                    }
                    variant="outlined"
                    required
                />
                <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                >
                  {
                      !props?.isEdit && <Button variant="contained" size='small' type="submit"
                                                onClick={(e) => {
                                                  onSaveAndContinueClicked(e, true)
                                                }}>
                        {t("salvaEProsegui")}
                      </Button>
                  }

                  <Button 
                  size='small'
                      variant="contained"
                      onClick={(e) => {
                        props?.isEdit? editSottoConto(): onSaveAndContinueClicked(e, false)
                      }}
                  >
                    {t("salvaEdESCI")}
                  </Button>

                  {
                      props?.isEdit && <Button
                      size='small'
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setOpenDelete(!openDelete)
                          }}
                      >
                        {t("elimina")}
                      </Button>}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
  );

  return (
      <>
        {isLoading ? <LoadingSpinner/> : SottoConto}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{width: '100%'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
          <Alert onClose={handleAlertClose} sx={{color:"#fff"}} severity={severity}>
            {errorMsg}
          </Alert>
        </Snackbar>
        <DeleteDialog open={openDelete} handleClose={handleCloseDeleteDialog} onConfirm={confirmDelete}/>
      </>);
}


export default AddSottoConto;
