/***
 @description
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import { DeleteDialog } from "components/DeleteDialog";
import MuiAlert from "@mui/material/Alert";
import { debounce } from "lodash";
import { getDataFromStorage } from "storage/StorageData";
import defaultString from "constants/defaultString.json";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { resetToken } from "apiURL/commonFunctions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddEcoConto = (props) => {
  const { i18n, t } = useTranslation();
  const [isSaveNConti, setIsSaveNConti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [accountingSubject, setAccountingSubject] = useState(null);
  const [reload, setReload] = useState(false);
  const [severity, setSeverity] = useState("success");
  const { dispatch } = useContext(AuthContext);
  const navigate=useNavigate()

  useEffect(() => {
    getDataFromStorage(defaultString.accountingSubject).then((response) => {
      setAccountingSubject(response);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    codice: Yup.number()
      .required(t("codice") + " " + t("richiede"))
      .integer("Must be an integer")
      .typeError("Must be a number"),
    conto: Yup.string().required(t("descrizione") + " " + t("richiede")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      codice: "",
      conto: "",
    },
    validationSchema,
    onSubmit: async (values, { setErrors }) => {
      console.log("Form values:", values);

      const requestObject = {};
      let isCodeUnique = false;

      requestObject.code = values.codice;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.type = 2;
      requestObject.accountId = props?.isEdit ? props.stateObj?._id : 0;

      const checkCodeResponse = await API.post(
        apiURL.service_check_code_exist,
        requestObject
      );
      if (checkCodeResponse && checkCodeResponse?.data?.success) {
        if (checkCodeResponse?.data?.data?.isExist) {
          isCodeUnique = false;
          formik?.setFieldTouched("codice", true, false);
          setErrors({
            codice: t("codeIsAlreadyInUser"),
          });
        } else {
          isCodeUnique = true;
        }
      }

      const requestNameObject = {}
      let isNameUnique = false
      requestNameObject.name = values.conto
      requestNameObject.accountingSubjectId = accountingSubject?._id
      requestNameObject.type = 2
      requestNameObject.accountId = props?.isEdit ? props.stateObj?._id : 0;

      const checkNameResponse = await API.post(apiURL.service_check_account_name_exist,requestNameObject)
      if(checkNameResponse && checkNameResponse?.data?.success) {
        if(checkNameResponse?.data?.data?.isExist) {
          isNameUnique = false
          formik?.setFieldTouched('conto', true, false);
          setErrors({
            conto: t('accountNameAlreadyInUse')
          })
        } else {
          isNameUnique = true
        }
      }

      if (isCodeUnique && isNameUnique) {
        await addConto();
      }
    },
  });

  const doLogout = async (resErr) => {
    const data = await resetToken()
    if (data === true) {
      dispatch(
        { type: "LOGOUT", payload: null },
        { type: "ACCOUNT_LOGOUT", payload: null }
        
      );
      localStorage.setItem("userToken", null);
      localStorage.setItem("accountingSubject", null);
      localStorage.setItem("user", null);
        navigate('/',{ state: { isModalPopup: true} })
    }
}

  useEffect(() => {
    if (props && props?.stateObj) {
      formik.setValues({
        codice: props?.stateObj?.code ? props?.stateObj?.code : "",
        conto: props?.stateObj?.name ? props?.stateObj?.name : "",
      });
    }
  }, [props, props?.stateObj]);

  const checkCode = useCallback(
    debounce(async (value) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );

      const requestObject = {};
      requestObject.code = value;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.type = 2;
      requestObject.accountId = props?.isEdit ? props.stateObj?._id : 0;

      API.post(apiURL.service_check_code_exist, requestObject).then(
        (checkCodeResponse) => {
          if (checkCodeResponse?.data?.data?.isExist) {
            formik?.setFieldTouched("codice", true, false);
            formik?.setErrors({
              codice: t("codeIsAlreadyInUser"),
            });
          } else {
            formik?.setErrors({
              codice: null,
            });
          }
        }
      );
    }, 500),
    []
  );

  const checkName = useCallback(
    debounce(async (value) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const requestObject = {};
      requestObject.name = value;
      requestObject.accountingSubjectId = accountingSubject?._id;
      requestObject.type = 2;
      requestObject.accountId = props?.isEdit ? props.stateObj?._id : 0;

      API.post(apiURL.service_check_account_name_exist, requestObject).then(
        (checkCodeResponse) => {
          if (checkCodeResponse?.data?.data?.isExist) {
            formik?.setFieldTouched("conto", true, false);
            formik?.setErrors({
              conto: t("accountNameAlreadyInUse"),
            });
          } else {
            formik?.setErrors({
              conto: null,
            });
          }
        }
      );
    }, 500),
    []
  );

  const addConto = async () => {
    console.log("TTT");
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    if (!!formik.values) {
      setIsLoading(true);

      const requestObject = {};
      requestObject.code = formik.values.codice ? formik.values.codice : "";
      requestObject.type = 2;
      requestObject.accountingSubjectId = accountingSubject._id;
      requestObject.name = formik.values.conto ? formik.values.conto : "";

      API.post(apiURL.service_add_account, requestObject)
        .then((response) => {
          setIsLoading(false);
          setReload(true);
          if (response.status === apiStatusCode.CREATED) {
            setOpen(true);
            setErrorMsg(t("common.addSuccess"));
            setSeverity("success");

            formik.resetForm();

            if (isSaveNConti) {
              setIsSaveNConti(false);
            } else {
              props.handleClose({ reload: true });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
              doLogout()
            } else {
              setErrorMsg(t("common.badRequest"));
            }
          }
        });
    }
  };

  const handleCodeChange = (event) => {
    formik.handleChange(event);
    if (event.target.value.length > 0) {
      checkCode(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    formik.handleChange(event);
    if (event.target.value.length > 0) {
      checkName(event.target.value);
    }
  };

  const editConto = async () => {
    if (!!formik.values && formik.isValid) {
      let isCodeChanged = false;

      if (props?.stateObj?.code !== formik.values.codice) {
        isCodeChanged = true;
      }
      let isCodeUnique = true;

      if (isCodeChanged) {
        const codeRequestObject = {};
        codeRequestObject.code = formik.values.codice;
        codeRequestObject.accountingSubjectId = accountingSubject?._id;
        codeRequestObject.type = 2;
        codeRequestObject.accountId = props?.isEdit ? props.stateObj?._id : 0;

        const checkCodeResponse = await API.post(
          apiURL.service_check_code_exist,
          codeRequestObject
        );
        if (checkCodeResponse && checkCodeResponse?.data?.success) {
          if (checkCodeResponse?.data?.data?.isExist) {
            isCodeUnique = false;
            formik.setErrors({
              codice: t("codeIsAlreadyInUser"),
            });
          } else {
            isCodeUnique = true;
          }
        }
      }
      if (isCodeUnique) {
        const requestObject = {};
        requestObject.code = formik.values.codice ? formik.values.codice : "";
        requestObject.type = 2;
        requestObject.accountingSubjectId = accountingSubject._id;
        requestObject.name = formik.values.conto ? formik.values.conto : "";

        API.put(
          `${apiURL.service_update_account}/${props.stateObj?._id}`,
          requestObject
        )
          .then((response) => {
            setIsLoading(false);
            setReload(true);
            if (response.status === apiStatusCode.SUCCESS) {
              setOpen(true);
              setErrorMsg(t("common.updateSuccess"));
              setSeverity("success");
              formik.resetForm();

              props.handleClose({ reload: true });
            } else {
              setOpen(true);
              setSeverity("error");
              setErrorMsg(t("common.internalServerError"));
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setOpen(true);
            setSeverity("error");
            if (error.code === defaultString.network_error)
              setErrorMsg(t("common.networkError"));
            else if (error.code === defaultString.bad_request) {
              if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
                doLogout()
              } else {
                setErrorMsg(t("common.badRequest"));
              }
            }
          });
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };

  const fnDeleteAccount = async () => {
    // const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

    const idsToBeDeleted = JSON.stringify({
      accountIds: [props.stateObj?._id],
      accountingSubjectId: accountingSubject?._id,
    });

    await API.post(apiURL.service_delete_account, idsToBeDeleted)
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          formik.resetForm();
          props.handleClose({ reload: true });
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            doLogout()
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const confirmDelete = async () => {
    await fnDeleteAccount();
    setOpenDelete(!openDelete);
  };

  const backToList = (reload) => {
    props.handleClose({ reload: reload });
    formik.resetForm();
    setIsSaveNConti(false);
  };

  const onSaveAndContinueClicked = (e, isContinue) => {
    console.log("1");
    e.preventDefault();
    console.log("2");
    setIsSaveNConti(isContinue);
    console.log("3");
    formik?.handleSubmit();
    console.log("4");
  };

  const AddEcoConto = (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      // onClose={props.handleClose}
    >
      <Box
        sx={{
          width: "70%",
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            
            borderWidth: "1px",
            borderColor: "black",
            borderRadius: 2,
            padding: 1.5,
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              color: "black",
            }}
          >
            {props?.isEdit ? t("changeConto") : t("creaConto")}
          </Typography>

          <Button
            size="small"
            variant="contained"
            onClick={() => {
              backToList(reload);
            }}
          >
            {t("indietro")}
          </Button>
        </Box>
        <Divider />

        <Box
          sx={{
            
            py: 3,
            height: "50vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
              border: "4px solid #ffffff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#2c70cf",
            },
          }}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              maxWidth: 550,
              width: "100%",
              margin: "auto",
            }}
          >
            <Stack spacing={2}>
              <TextField
              size='small'
                fullWidth
                id="outlined-basic"
                label={t("statoPatrimoniale") + " / " + t("contoEconomico")}
                name="name"
                // value={formik.values.name}
                value={t("contoEconomico")}
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                required
              />
              <TextField
              size='small'
                fullWidth
                id="outlined-basic"
                label={t("codice")}
                name="codice"
                value={formik.values.codice}
                onChange={handleCodeChange}
                onBlur={formik.handleBlur}
                error={formik.touched.codice && Boolean(formik.errors.codice)}
                helperText={formik.touched.codice && formik.errors.codice}
                variant="outlined"
                required
              />
              <TextField
              size='small'
                fullWidth
                id="outlined-basic"
                label={t("descrizione")}
                name="conto"
                value={formik.values.conto}
                onChange={handleNameChange}
                onBlur={formik.handleBlur}
                error={formik.touched.conto && Boolean(formik.errors.conto)}
                helperText={formik.touched.conto && formik.errors.conto}
                variant="outlined"
                required
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {!props?.isEdit && (
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    onClick={(e) => {
                      onSaveAndContinueClicked(e, true);
                    }}
                  >
                    {t("salvaEProsegui")}
                  </Button>
                )}

                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    console.log("Click");
                    props?.isEdit
                      ? editConto()
                      : onSaveAndContinueClicked(e, false);
                  }}
                >
                  {t("salvaEdESCI")}
                </Button>

                {props?.isEdit && (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpenDelete(!openDelete);
                    }}
                  >
                    {t("elimina")}
                  </Button>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : AddEcoConto}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <DeleteDialog
        open={openDelete}
        handleClose={handleCloseDeleteDialog}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default AddEcoConto;
